.discharge-history {
    font-size: 20px;
}

.discharge-history .esp-table.history {
    width: 1198px;
}

.discharge-history .esp-table.history thead td {
    height: 60px;
}

.discharge-history .esp-table.history td {
    height: 43px;
}

.discharge-history .esp-table.history td.max {
    width: 1197px !important;
    max-width: 1198px !important;;
}

.discharge-history .esp-table.history .esp-table-header {
    font-size: 20px;
}

.discharge-history .esp-table.history .esp-table-body {
    /*height: 58vh;*/
    font-size: 20px;
}

.discharge-history .esp-table.history td:nth-child(1){
    width: 472px;
    text-align: left;
    padding: 0 0 0 10px !important;
}

.discharge-history .esp-table.history td:nth-child(2){
    width: 195px;
}

.discharge-history .esp-table.history td:nth-child(3){
    width: 198px;
}

.discharge-history .esp-table.history td:nth-child(4){
    width: 166px;
}

.discharge-history .esp-table.history td:nth-child(5){
    width: 166px;
}

.discharge-history .table-descr {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}

.discharge-history .table-descr .ml90{
    margin-left:  90px;
}
.date-select {
    position: relative;
}

.date-select-block {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 1px solid rgb(170, 170, 170);
    position: absolute;
    top: -57px;
    left: 22px;
    height: 153px;
    width: 82px;
    z-index: 2;
    background-color: rgb(247, 246, 246);
    overflow-y: scroll;
    overflow-x: hidden;
    color: #444444;
    user-select: none;
    display: flex;
}

.date-select-block ul {
    text-align: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.date-select-block ul:nth-child(1),
.date-select-block ul:nth-child(3) {
    width: 40%;
}

.date-select-block ul:nth-child(2) {
    width: 20%;
}

.date-select-block ul li {
    text-decoration: none;
    list-style-type: none;
}

.date-select-block ul li.corner-color {
    opacity: 0.3;
    font-size: 18px !important;
}

.date-select-block ul li.focus-color {
    background-color: #AAAAAA;
    color: #fff;
}


.date-select-block::-webkit-scrollbar {
    display: none;
}

.date-select-block {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
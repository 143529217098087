@font-face {
  font-family: "Roboto Condensed";
  src: url('./fonts/roboto/RobotoCondensed-Bold.ttf');
  src: url('./fonts/roboto/RobotoCondensed-BoldItalic.ttf');
  src: url('./fonts/roboto/RobotoCondensed-Italic.ttf');
  src: url('./fonts/roboto/RobotoCondensed-Light.ttf');
  src: url('./fonts/roboto/RobotoCondensed-LightItalic.ttf');
  src: url('./fonts/roboto/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: "Roboto";
  src: url('./fonts/roboto/roboto_bold.ttf');
  src: url('./fonts/roboto/roboto_italic.ttf');
  src: url('./fonts/roboto/roboto_light.ttf');
  src: url('./fonts/roboto/roboto_medium.ttf');
  src: url('./fonts/roboto/roboto_regular.ttf');
}

@font-face {
  font-family: "Noto";
  src: url('./fonts/noto/NotoSerifJP-Bold.otf') format("opentype");
  src: url('./fonts/noto/NotoSerifJP-ExtraLight.otf') format("opentype");
  src: url('./fonts/noto/NotoSerifJP-Light.otf') format("opentype");
  src: url('./fonts/noto/NotoSerifJP-Medium.otf') format("opentype");
  src: url('./fonts/noto/NotoSerifJP-Regular.otf') format("opentype");
}

body {
  width: auto !important;
  height: auto !important;
  margin: 0;
  color: black;
  font-family: var(--main-font), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

textarea {
  resize: none;
  /*pointer-events: none;*/
  border: 1px solid #808080 !important;
}

input {
  border: 1px solid #808080 !important;
}

.container-fluid {
  padding: 0;
}

:focus {
  outline: none !important;
  /*background: #FE7069;*/
}

body {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

a {
  color: #006ce5;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
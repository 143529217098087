.html2canvas-container {
    width: 3508px !important;
    height: 2480px !important;
}

.patient-report {
    margin-top: -20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 90px);
    width: calc(100%);
}

.patient-report .middle-icons {
    position: absolute;
    top: 5px;
    left: 40%;
    color: #fff;
}

.patient-report .middle-icons div {
    border-right: 1px solid #fff;
    display: inline-block;
    height: 30px;
    margin: -8px 10px;
}

.patient-report .right-icons {
    position: absolute;
    top: 10px;
    right: 0;
    width: 685px;
}

.patient-report .report-date-picker {
    width: 390px;
}

.patient-report .discharge-modal .modal-content {
    width: 420px;
    height: 425px;
    box-shadow: 5px 5px 10px #888888;
}

.patient-report .discharge-modal .modal-content .modal-header {
    height: 55px;
}

.patient-report .discharge-modal .modal-content .modal-body {
    font-size: 16px;
    padding-left: 50px;
}

.patient-report .discharge-modal .modal-content .mt10 {
    margin-top: 10px;
}

.patient-report .discharge-modal .modal-content .mb10 {
    margin-bottom: 10px;
}

.patient-report .discharge-modal .modal-content .red,
.patient-report .discharge-reconfirm-modal .modal-content .red {
    color: #E61610 !important;
    font-weight: bold;
}

.patient-report .discharge-modal .modal-content .m35 {
    margin-top: 35px;
}

.patient-report .discharge-modal .modal-content .modal-footer {
    font-size: 16px;
    height: 55px;
    min-height: 55px;
    padding: 0;
}

.patient-report .discharge-reconfirm-modal .modal-content {
    width: 420px;
    min-height: 50px;
    top: 255px;
}

.patient-report .discharge-reconfirm-modal .modal-content .modal-header {
    height: 55px;
}

.patient-report .discharge-reconfirm-modal .modal-content .modal-body {
    font-size: 16px;
    padding-left: 50px;
    min-height: 50px;
}

.patient-report .discharge-reconfirm-modal .modal-content .modal-footer {
    font-size: 16px;
    height: 55px;
    min-height: 55px;
    padding: 0;
}

.patient-report .report-content {
    width: calc(100% - 10px);
    zoom: var(--report-zoom);
    /*overflow-y: scroll;*/
    height: 1038px;
    -moz-transform: scale(var(--report-zoom));
    -moz-transform-origin: 0 0;

}

.patient-report .report-content-2 {
    width: calc(100% - 10px);
    zoom: var(--report-zoom);
    /*overflow-y: scroll;*/
    height: 1038px;
    -moz-transform: scale(var(--report-zoom));
    -moz-transform-origin: 0 0;
}

.report-content::-webkit-scrollbar,
.report-content-2::-webkit-scrollbar {
    display: none;
}

.report-content .hidden,
.report-content-2 .hidden {
    display: none;
}

.patient-report span.active {
    background: rgba(255, 255, 255, 0.3);
    padding: 10px 0;
}

.patient-report .edit-modal .modal-content {
    width: 665px;
    box-shadow: 5px 5px 10px #888888;
}

.patient-report .edit-modal .modal-content .modal-header {
    height: 65px;
}

.patient-report .edit-modal .modal-content .modal-body {
    font-size: 20px;
    padding: 10px 36px;
    height: 260px;
}

.patient-report .edit-modal .modal-content .modal-body input.enroll-input {
    max-width: 345px;
}

.patient-report .edit-modal .modal-content .modal-body input.enroll-input-sm {
    max-width: 280px;
}

.patient-report .edit-modal .modal-content .modal-body .w330 {
    width: 345px !important;
    max-width: 345px !important;
}

.patient-report .edit-modal .modal-content .mt10 {
    margin-top: 10px;
}

.patient-report .edit-modal .modal-content .mb10 {
    margin-bottom: 10px;
}

.patient-report .edit-modal .modal-content .red {
    color: #E61610 !important;
    font-weight: bold;
}

.patient-report .edit-modal .modal-content .m35 {
    margin-top: 25px;
}

.patient-report .edit-modal .modal-content .modal-footer {
    font-size: 20px;
    height: 65px;
    min-height: 55px;
    padding: 0;
}

.patient-report .edit-modal .modal-footer button {
    margin: 10px 70px;
}

.patient-report .one-line > div:nth-child(1) {
    max-width: 205px;
    width: 160px;
}

.patient-report.ja .one-line > div:nth-child(1) {
    width: 205px;
}

.patient-report .iresp-modal .modal-dialog {
    margin: 15% 38%;
}

.patient-report .h45 {
    height: 45px !important;
}

.patient-report .h85 {
    height: 85px !important;
}

/*/* ===== Scrollbar CSS ===== */
/* Firefox */
.patient-report {
    scrollbar-width: thin;
    scrollbar-color: #242424 #ffffff;
}

/* Chrome, Edge, and Safari */
.patient-report::-webkit-scrollbar {
    width: 6px;
    position: absolute;
    left: 100px;
}

.patient-report::-webkit-scrollbar-track {
    background: transparent;
}

.patient-report::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 15px;
}

.patient-report .export-csv-modal {

}

.patient-report .export-csv-modal .modal-dialog {
    margin: auto auto;
}

.patient-report .export-csv-modal .modal-content {
    width: 370px;
    height: 350px;
}

.patient-report .export-csv-modal div.inline {
    display: inline-block;
    margin: 10px 0 0 0;
}

.patient-report .export-csv-modal .w110px {
    width: 120px;
}

.patient-report .export-csv-modal input {
    width: 160px;
    padding: 0 5px;
    color: #000 !important;
}


.patient-report .export-csv-modal img {
    display: none;
}

.patient-report .export-csv-modal .modal-footer button {
    margin: 10px 0;
    min-width: 150px;
}

.patient-report .export-csv-modal .calendar {
    width: 500px;
    position: absolute;
    margin: 60px 0 0 10px !important;
}

.patient-report .caution {
    color: #444;
    font-style: italic;
    margin-top: 2px;
    font-size: 16px;
}

.patient-report .caution-2 {
    color: #444;
    font-style: italic;
    /*margin-top: 10px;*/
    font-size: 16px;
}

/*#data {*/
    /*height: 800px;*/
    /*overflow-y: scroll;*/
/*}*/

@media only screen and (max-width: 1680px) {

    .patient-report .report-content {
        height: 1105px;
    }
}

@media only screen and (max-width: 1400px) {

    .patient-report .report-content {
        height: 1105px;
    }
}

@media only screen and (max-width: 1385px) {

    .patient-report .report-content {
        height: 1105px;
    }

    .patient-report .report-content-2 {
        height: 1350px;
    }
}

@media only screen and (max-width: 1280px) {
    .patient-report .report-content {
        height: 1090px;
    }

    .patient-report .report-content-2 {
        height: 1350px;
    }
}

@media only screen and (max-device-width: 1024px) {
    .patient-report .caution-2 {
        margin-top: 10px;
    }
}

@media only print {
    .patient-report .report-content {
        width: 100%;
        zoom: var(--report-zoom);
        overflow-y: hidden !important;
    }

    .patient-report .report-content-2 {
        width: 100%;
        zoom: var(--report-zoom);
        overflow-y: hidden !important;
    }
}
.bg-black {
    background-color: #000;
    min-height: 100vh;
    height: 100%;
}

.bg-white {
    background-color: #fff;
    min-height: 100vh;
    height: 100vh;
    overflow-y: auto;
}

.live-tile {
    font-size: 14px;
    width: 100%;
    height: 100%;
}

.live-tile .filter {
    font-size: 14px;
    background-color: #F7F6F6;
    border-bottom: 1px solid #DFDEDE;
}

.live-tile .filter .hamburger i {
    font-weight: bold;
    font-size: 20px;
    font-style: normal;
    color: black;
}

.live-tile .filter .inline {
    display: inline-block;
    padding: 5px 0 5px 15px;
    opacity: 0.5;
    cursor: pointer;
}

.live-tile .filter .user-dropdown .inline {
    opacity: 1.0;
}

.live-tile .select-number .esp-select {
    width: 54px;
    display: inline-block;
    margin: 5px 5px;
}

.live-tile .select-number .esp-select__single-value {
    font-weight: bold;
    margin-top: -3px;
}

.live-tile .select-number .esp-select__dropdown-indicator {
    margin-left: -5px;
}

.live-tile .select-number .esp-select__indicators {
    width: 10px;
    height: 30px;
}

.live-tile .select-number .esp-select__control {
    height: 32px;
    width: 54px;
}

.live-tile .select-number .esp-select__menu {
    width: 54px !important;
    margin-left: 3%;
}

.live-tile .select-number .esp-select__menu-list {
    overflow-y: auto;
    height: 215px;
    width: 54px;
}

.live-tile .select-number {
    display: inline-block;
    width: 63%;
    text-align: right;
    font-size: 18px;
    color: #ababab;
}

.live-tile .select-number .esp-select__option {
    color: black;
}

.live-tile .select-alarm-type {
    display: inline-block;
    width: 2%;
    margin-left: 25px;
    cursor: pointer;
}

.live-tile .select-alarm-type img {
    width: 26px;
    margin-top: -5px;
}

.live-tile .inline div {
    /*text-align: center !important;*/
    /*margin-left: 10px;*/
}

.live-tile .filter .selected {
    opacity: 1.0;
}

.live-tile .filter .label {
    margin: 0 5px;
}

.live-tile .filter .count {
    color: red;
    font-weight: bold;
}

.live-tile .tiles {
    line-height: 0.5;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: calc(var(--tile-width-px) * var(--live-tile-cols));
    margin: auto auto;
}

.live-tile .left {
    width: 30%;
    display: inline-block;
}

.live-tile .user-dropdown {
    width: calc(5% - 30px);
    text-align: right;
    display: inline-block;
    padding-right: 20px;
}

.live-tile .user-dropdown .user {
    cursor: pointer;
    width: 26px;
    margin-top: -5px;
}

.tiles .blink {
    color: #E61610;
    /*-webkit-animation: fadein 1s; !* Safari, Chrome and Opera > 12.1 *!*/
    /*-moz-animation: fadein 1s; !* Firefox < 16 *!*/
    /*-ms-animation: fadein 1s; !* Internet Explorer *!*/
    /*-o-animation: fadein 1s; !* Opera < 12.1 *!*/
    /*animation: fadein 1s;*/
    /*animation-iteration-count: infinite;*/
}

.tiles .non-blink {
    color: #E61610;
    opacity: 1.0;
}

.user-dropdown .dropdown .dropdown-menu {
    padding: 0;
    margin-left: -200px;
    font-size: 16px;
    width: 250px;
    border-radius: 0;
}

.user-dropdown .dropdown .dropdown-menu.ja {
    margin-left: -291px;
    width: 351px;
}

.user-dropdown .dropdown .dropdown-item {
    padding: 0.6rem 1.25rem;
    text-align: left;
}

.user-dropdown .dropdown .dropdown-item svg {
    color: #999;
}

.user-dropdown .dropdown .dropdown-item:first-child {
    border-bottom: 1px solid #ddd;
}

.user-dropdown .dropdown .dropdown-item:last-child {
    border-top: 1px solid #ddd;
}

.user-dropdown .dropdown .dropdown-item:focus,
.user-dropdown .dropdown .dropdown-item:hover {
    outline: none !important;
    background: var(--main-color);
    color: white;
    cursor: pointer;
}

.user-dropdown .dropdown .dropdown-item .text {
    padding-left: 15px;
}

.user-dropdown .dropdown .dropdown-item .text.pl0 {
    padding-left: 0;
}

.user-dropdown .dropdown .dropdown-item .text.pl5 {
    padding-left: 5px;
}

.user-dropdown .dropdown > button::selection,
.dropdown > button:active,
.dropdown > button:visited,
.dropdown > button:hover,
.dropdown > button:focus {
    outline: none !important;
    /*background: #FE7069;*/
}

.user-dropdown .toggle-user {
    background-color: transparent;
    display: inline-block;
    border: none;
}

.user-dropdown .toggle-user:hover {
    background-color: transparent;
    display: inline-block;
    border: none;
}

.user-dropdown .esp-modal {
    z-index: 1001;
}

.user-dropdown .esp-modal .modal-content {
    min-height: 85px;
}

.user-dropdown .esp-modal .modal-body {
    height: 85px;
    min-height: 85px;
    text-align: left;
}

.user-dropdown .esp-modal .modal-footer {
    text-align: center;
}

.user-dropdown .esp-modal .modal-footer button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.live-tile .status-loading {
    width: 50px;
    height: 50px;
    position: absolute;
    margin-top: -15px;
    margin-left: 0;
}

.live-tile .dash {
    display: inline-block;
    /* margin-top: -22px; */
    position: absolute;
    top: 10px;
}

.live-tile .zoom-tile {
    zoom: var(--zoom-tile-zoom);
}

.live-tile .signal-aop1 {
    font-size: 4px;
    color: white;
    background: red;
    border-radius: 1px;
    padding: 2px;
    display: inline-block;
    position: absolute;
    margin-left: -12px;
    margin-top: -3px;
}

.live-tile .signal-aop3 {
    font-size: 4px;
    color: white;
    background: red;
    border-radius: 1px;
    padding: 2px;
    display: inline-block;
    position: absolute;
    margin-left: -12px;
    margin-top: 9px;
}

.live-tile .bad_signal {
    font-size: 20px !important;
}

.live-tile .inaccurate_signal {
    font-size: 20px !important;
    color: #929292;
}

.live-tile .resp-grey {
    color: #929292 !important;
}

.live-tile .esp-modal {
    z-index: 1001;
}

.live-tile .esp-modal .modal-content {
    min-height: 85px;
}

.live-tile .esp-modal .modal-body {
    height: auto;
    min-height: 85px;
}

.live-tile .esp-modal .modal-footer {
    text-align: center;
}

.live-tile .esp-modal .modal-footer button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.live-tile .dismiss-alarm {
    font-size: 20px;
}

.live-tile .dismiss-alarm .modal-dialog {
    margin: 10% 32%;
}

.live-tile .dismiss-alarm .modal-content {
    width: calc(30vw);
    height: calc(45vh);
}

.live-tile .dismiss-alarm .modal-title {
    font-size: calc((10vw + 10vh) * 0.08);
    color: rgb(42, 171, 224) !important;
}

.live-tile .dismiss-alarm .modal-body {
    font-size: calc((10vw + 10vh) * 0.08);
}

.live-tile .dismiss-alarm .modal-footer {
    font-size: calc((10vw + 10vh) * 0.08);
    min-height: 55px;
    display: flex;
    height: calc((10vw + 10vh) * 0.3);
    justify-content: center;
    align-items: center;
}

.live-tile .dismiss-alarm .modal-header {
    min-height: 55px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: calc((10vw + 10vh) * 0.3);
}

.live-tile .dismiss-alarm .modal-footer button {
    min-width: 0;
    margin: 0 30px;
}

.live-tile .sound-warning .modal-content {
    font-size: 20px;
    height: 200px;
    min-height: 100px;
}

.live-tile .sound-warning .modal-header {
    font-size: 20px;
    min-height: 50px;
    height: 50px;
}

.live-tile .sound-warning .modal-body {
    font-size: 20px;
    height: 100px;
    min-height: 50px;
}

.live-tile .sound-warning .modal-footer {
    font-size: 20px;
    min-height: 50px;
    height: 60px;
}

.live-tile .no-bed {
    margin-left: 5px;
    margin-top: 10px;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.live-tile .change-alarm-tone input[type=radio] {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    accent-color: black;
    display: inline-block;
    vertical-align: middle;
}

.live-tile .change-alarm-tone {
    font-size: 20px;
}


.live-tile .change-alarm-tone .modal-footer button:first-child {
    margin: 10px 40px 10px 0;
}

.live-tile .change-alarm-tone .modal-footer button:last-child {
    margin: 10px 0 10px 40px;
}

.live-tile .change-alarm-tone .modal-dialog {
    margin: 10% auto auto;
}

.live-tile .change-alarm-tone .modal-content {
    width: 500px;
}

.live-tile .change-alarm-tone .ml20 {
    margin-left: 20px;
}

.live-tile .change-alarm-tone .mt20 {
    margin-top: 20px;
}

.live-tile .change-alarm-tone .mb10 {
    margin-bottom: 10px;
}

.live-tile .live-tile-logo {
    font-size: 20px;
    font-weight: bold;
    color: #606060;
    cursor: auto !important;
    opacity: 1.0 !important;
    font-family: "Roboto", sans-serif;
}

.live-tile .live-tile-centre {
    font-size: 20px;
    margin-left: 10px;
    color: var(--main-color);
    text-shadow: -2px 2px 3px rgba(64,58,58,0.4);
    cursor: auto !important;
    opacity: 1.0 !important;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
}

.live-tile .esp-modal .modal-header {
    font-size: 20px;
}

.live-tile .esp-modal .modal-body {
    font-size: 20px;
}

.live-tile .esp-modal .modal-body p {
    margin-left: 20px;
    margin-right: 20px;
}

.live-tile .esp-modal .modal-footer {
    font-size: 20px;
}

.live-tile .esp-modal .modal-footer button{
    font-size: 20px;
}

.live-tile #sidebar {
    position: fixed;
    z-index: 2;
    top: 44px;
    left: 0;
}

.live-tile .filter .hamburger {
    padding: 0;
}

.live-tile .filter .hamburger i {
    padding: 40px 15px;
}

.live-tile .filter .hamburger .show {
    left: 0 !important;
    transition: 0.5s;
}

.live-tile .filter .hamburger .hide {
    left: -15% !important;
    transition: 0.5s;
}
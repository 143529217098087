.select-nurse {
    display: flex;
}

.select-nurse .esp-select {
    width: 425px;
    height: 35px;
    margin-left: 20px;
}

.qr-code {
    margin-top: 30px;
}

.qrcode-div {
    width: 295px;
    height: 290px;
    background-color: rgba(214, 214, 214, 0.5);
    padding: 25px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    margin-left: 5px;
    margin-top: 40px;
}

.qrcode-img {

}
@media only screen and (max-device-width: 980px) {
    .sleep-trend table td .txt-wrapper > div:nth-child(1) {
        font-size: 12px;
    }

    .sleep-trend table td .txt-wrapper > div:nth-child(2) {
        font-size: 12px;
    }

    .sleep-trend text {
        font-size: 14px;
    }

    .vital-sign-header {
        font-size: 14px;
    }

    .vital-sign-header .cursor-measurement .fieldset.fieldset-threshold {
        font-size: 28px;
    }

    .vital-sign-header .reading-resp {
        font-size: 28px;
    }

    .vital-sign-content .content-wrapper > div.info .time-text {
        margin-left: 0;
        font-size: 14px;
    }

    .vital-sign-content .content-wrapper > div.info .canvas-ruler {
        font-size: 14px;
    }

    .vital-sign-content .svg-text-time {
        font-size: 14px;
    }

    .rept-header .rept-header-left {
        width: 35%;
    }

    .rept-header .rept-header-center {
        width: 35%;
    }

    .rept-header .rept-header-right {
        width: 25%;
    }
}
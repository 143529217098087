.iresp-filter-date {
    display: inline-block;
    margin-left: 20px;
    text-transform: none;
    font-weight: normal;
    font-size: 20px;
    width: 300px;
}

.iresp-filter-date .name-12 .caret-left,
.iresp-filter-date .name-12 .caret-right {
    font-size: 40px;
    vertical-align: top;
    cursor: pointer;
    opacity: .4;
    pointer-events: none;
    margin-top: -5px;
}

.iresp-filter-date .name-12 .caret-left.active,
.iresp-filter-date .name-12 .caret-right.active {
    color: #fff;
    pointer-events: auto;
    opacity: 1.0;
}

.iresp-filter-date .date-label {
    display: inline-block;
    margin-right: 15px;
}

.iresp-filter-date .react-calendar {
    margin-top: 2px;
}

.iresp-filter-date .date {
    font-weight: bold;
    padding: 0 20px;
    vertical-align: top;
    display: inline-block;
    margin-top: 0;
    cursor: pointer;
}

.iresp-filter-date input {
    height: 33px;
    border: 1px solid #909090;
    border-radius: 0.25em;
    width: 285px;
    cursor: pointer;
    padding-left: 40px;
}

.iresp-filter-date .input-group-custom {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    height: 10px;
}

.iresp-filter-date .input-group-custom .input-group-custom-prepend {
    position: absolute;
    top: 2px;
    left: 10px;
    opacity: 0.3;
}
.patient-transfer {
    font-size: 20px;
}

.patient-transfer .data {
    padding: 5px 0;
    position: relative;
}

.patient-transfer .tabs {
    /*border-bottom: 5px solid var(--main-color);*/
    margin-right: 20px;
    /*margin-bottom: 10px;*/
}

.patient-transfer .devider {
    border-bottom: 7px solid var(--main-color);
    position: absolute;
    width: calc(100% - 0px);
    top: 34px;
    left: 0;
}

.patient-transfer .tabs a {
    text-align: center;
    /*min-width: 165px;*/
    padding: 0 20px;
    display: inline-block;
    /*border-bottom: 5px solid black;*/
    text-decoration: none !important;
    border: 1px solid rgba(200, 200, 200, 1.0);
    border-bottom: 0;
    border-right: 0;
    border-radius: 8px 8px 0 0;
    color: black !important;
    cursor: pointer;
}

.patient-transfer .tabs a:last-child {
    border-right: 1px solid rgba(200, 200, 200, 1.0);
}

.patient-transfer .tabs a:hover {
    text-decoration: none;
}

.patient-transfer .tabs a.selected {
    font-weight: bold;
    /*border: 1px solid var(--main-color);*/
    /*border-bottom: 5px solid rgba(1, 1, 1, 0);*/
    /*border-radius: 8px 8px 0 0;*/
    /*text-decoration: underline;*/
    background-color: var(--main-color);
    color: white !important;
}

.patient-transfer input.filter {
    margin-left: 20px;
    width: 285px;
    border-radius: 5px;
    padding-left: 10px;
}

.patient-transfer .descr {
    opacity: 0.4;
    font-style: italic;
    margin-bottom: 10px;
}

.patient-transfer .table-descr {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}

.patient-transfer .table-descr .ml90{
    margin-left:  90px;
}

.patient-transfer table {
    font-size: 20px;
}

.patient-transfer table td {
    font-size: 20px;
    padding-left: 10px !important;
    text-align: left;
    /*white-space: nowrap;*/
    overflow: hidden;
    text-overflow: clip;
    display: table-cell;
    word-wrap: break-word;
}

.patient-transfer .esp-table.bed-allocation {
    width: calc(970px - 255px);
    margin-top: 30px;
}

.patient-transfer .esp-table.bed-allocation thead td {
    height: 60px;
}

.patient-transfer .esp-table.bed-allocation td {
    height: 43px;
}

.patient-transfer .esp-table.bed-allocation td.max {
    width: calc(970px - 256px) !important;
    max-width: calc(970px - 255px) !important;;
}

.patient-transfer .esp-table.bed-allocation .esp-table-body {
    /*height: 58vh;*/
}

.patient-transfer .esp-table.bed-allocation td:nth-child(1){
    width: 465px;
}

.patient-transfer .esp-table.bed-allocation td:nth-child(2){
    width: 249px;
}

.patient-transfer .esp-table.bed-allocation td:nth-child(3){
    width: 250px;
    display: none;
    visibility: hidden;
}

.patient-transfer .esp-table.bed-assignments {
    width: 1300px;
}

.patient-transfer .esp-table.bed-assignments thead td {
    height: 60px;
}

.patient-transfer .esp-table.bed-assignments td {
    height: 43px;
    /*word-break: unset;*/
    cursor: auto;
}

.patient-transfer .esp-table.bed-assignments td.max {
    width: 1299px !important;
    max-width: 1300px !important;;
}

.patient-transfer .esp-table.bed-assignments .esp-table-body {
    /*height: 58vh;*/
}

.patient-transfer .esp-table.bed-assignments td:nth-child(1){
    width: 260px;
}

.patient-transfer .esp-table.bed-assignments td:nth-child(2){
    width: 390px;
}

.patient-transfer .esp-table.bed-assignments td:nth-child(3){
    width: 197px;
}

.patient-transfer .esp-table.bed-assignments td:nth-child(4){
    width: 250px;
}

.patient-transfer .esp-table.bed-assignments td:nth-child(5){
    width: 202px;
    padding: 0 10px !important;
}

.patient-transfer td.status-error {
    background-color: rgb(244, 173, 156);
}

.patient-transfer .iresp-dropdown .select {
    width: 255px;
}

.patient-transfer .iresp-dropdown select.selected {
    font-weight: normal;
}

.patient-transfer .link {
    color: #2f76b5;
    /*text-decoration: underline;*/
    cursor: auto;
}

.patient-transfer .link a {
    text-decoration: underline !important;
    cursor: pointer;
    color: #2f76b5 !important;
}

.patient-transfer .custom-dropdown {
    position: relative;
}

.patient-transfer .iresp-select .iresp-select-items {
    width: 440px;
    z-index: 10;
    opacity: 1.0;
    right: 15px;
}

.patient-transfer .iresp-select .iresp-select-items div {
    border: none;
    padding: 0;
    height: 40px;
    min-height: 40px;
    cursor: none;
    opacity: 1.0;
}

.patient-transfer .iresp-select .iresp-select-items .custom-dropdown div div {
    width: 50%;
    border: 1px solid;
    min-height: 40px;
    height: 40px;
    border-top: none;
    padding-left: 10px;
    cursor: pointer;
}

.patient-transfer .iresp-select .iresp-select-items .custom-dropdown .group {
    background-color: #fff;
}

.patient-transfer .iresp-select .iresp-select-items .custom-dropdown .group:hover {
    background-color: #E5E5E5;
}

.patient-transfer .iresp-select .iresp-select-items .custom-dropdown .group .empty {
    width: 100%;
    border: 1px solid black;
    border-top: none;
}

.patient-transfer .iresp-select .iresp-select-items .custom-dropdown div div:first-child {
    border-right: none;
}

.patient-transfer .iresp-select .iresp-select-items .custom-dropdown div:first-child div {
    border-top: 1px solid;
    font-weight: bold;
    background-color: #E5E5E5;
    cursor: default;
}

.patient-transfer .confirm-transfer-modal .modal-content {
    width: 420px;
}

.patient-transfer .confirm-transfer-modal .modal-content .modal-header {
    height: 55px;
}

.patient-transfer .confirm-transfer-modal .modal-content .modal-body {
    font-size: 16px;
    padding-left: 50px;
}

.patient-transfer .confirm-transfer-modal .modal-content .mt20 {
    margin-top: 20px;
}

.patient-transfer .confirm-transfer-modal .modal-content .mt10 {
    margin-top: 10px;
}

.patient-transfer .confirm-transfer-modal .modal-content .red {
    color: #E61610;
    font-weight: bold;
}

.patient-transfer .confirm-transfer-modal .modal-content textarea {
    width: 325px;
    padding: 5px 10px;
    pointer-events: auto;
}

.patient-transfer .confirm-transfer-modal .modal-content .modal-footer {
    font-size: 16px;
    height: 55px;
    min-height: 55px;
    padding: 0;
}

.patient-transfer .error {
    min-height: 30px;
    font-style: italic;
}

.patient-transfer tr.cursor-pointer {
    cursor: pointer;
}

.patient-transfer td:hover .iresp-sort .none-sort {
    visibility: visible;
}

.patient-transfer .report-icon {
    width: 25px;
    position: absolute;
    right: 10px;
    top: auto;
}

.patient-transfer .csv-icon {
    width: 30px;
    position: absolute;
    right: 50px;
    top: auto;
}

.patient-transfer .csv-icon-2 {
    width: 25px;
    position: absolute;
    right: 10px;
    top: auto;
    cursor: pointer;
}


.patient-transfer .close-img-wrapper {
    float: right;
    margin-top: -5px;
    cursor: pointer;
}

.patient-transfer .close-img-wrapper img{
    width: auto;
}
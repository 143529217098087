.sleep-pharse-trend {
    border: 1px solid rgb(192, 192, 192);
    margin-top: 20px;
    width: 1904px;
    min-height: 340px;
}

.sleep-pharse-trend .title {
    font-weight: bold;
    font-size: 20px;
    text-align: left !important;
}

.sleep-pharse-trend table {
    font-size: 20px;
    width: 100%;
    border-collapse: collapse;
}

.sleep-pharse-trend table th,
.sleep-pharse-trend table td {
    border: 1px solid rgb(192, 192, 192);
    height: 30px;
    text-align: left;
    padding: 0 0 0 10px;
}

.sleep-pharse-trend table th {
    background-color: rgb(246, 246, 246);
    padding-top: 3px;
    padding-bottom: 4px;
}

.sleep-pharse-trend table th.title {
    background-color: rgb(235, 235, 235);
}

.sleep-pharse-trend table td .td-flex-container {
    display: flex;
    flex: 2;
}

.sleep-pharse-trend table td .td-flex-container .value {
    text-align: right;
    width: 100%;
    padding: 3px 10px;
}

.sleep-pharse-trend table td:nth-child(1),
.sleep-pharse-trend table th:nth-child(1) {
    width: 7%;
    text-align: center;
}

.sleep-pharse-trend table td:nth-child(2),
.sleep-pharse-trend table th:nth-child(2) {
    width: 5%;
    text-align: center;
}

.sleep-pharse-trend table th:nth-child(3) {
    width: 8.5%;
}

.sleep-pharse-trend table th:nth-child(4),
.sleep-pharse-trend table th:nth-child(5),
.sleep-pharse-trend table th:nth-child(6),
.sleep-pharse-trend table th:nth-child(7){
    width: 14.5%;
}

.sleep-pharse-trend table th:nth-child(8) {
    width: 21.5%;
}

.sleep-pharse-trend table td:nth-child(8) {
    padding-left: 20px;
}

/* sleep trend */

.sleep-trend {
    border: 1px solid rgb(192, 192, 192);
    margin-top: 10px;
    width: 1904px;
    min-height: 540px;
}

.sleep-trend .title {
    font-weight: bold;
    font-size: 20px;
    background-color: rgb(229, 229, 229);
    height: 30px;
    margin: auto 0;
    padding: 0 0 0 10px;
    vertical-align: middle;
}

/* trend table */
.sleep-trend table {
    font-size: 20px;
    width: 100%;
    border-collapse: collapse;
}

.sleep-trend table td {
    border: 1px solid rgb(192, 192, 192);
    height: 77px;
    text-align: left;
    padding-left: 10px;
}

.sleep-trend table td:nth-child(1) {
    width: 8%;
}

.sleep-trend table td:nth-child(2) {
    width: 92%;
    padding: 0;
}

.sleep-trend table td .txt-wrapper {
    display: flex;
    flex-direction: column;
    flex: 2;
    height: 60px;
    font-weight: bold;
    font-size: 20px;
}

.sleep-trend table td .txt-wrapper > div:nth-child(1) {
    padding: 0 5px 10px 0;
}

.sleep-trend table td .txt-wrapper > div:nth-child(2) {
    padding: 0 15px 5px 5px;
    text-align: right;
}

.sleep-trend table td .txt-wrapper .txt-time {
    font-weight: normal;
}

.sleep-trend text {
    font-size: 20px;
}

.header-wrapper {
    display: flex;
    background-color: rgb(229, 229, 229);
    flex-direction: row;
    font-size: 20px;
}

.dl-color {
    width: 20px;
    height: 32px;
    background-color: rgb(11, 186, 231);
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 1px;
}

.sl-color {
    width: 20px;
    height: 32px;
    background-color: rgb(106, 225, 255);
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
}

.tts-color {
    width: 20px;
    height: 32px;
    display: inline-block;
    background-color: rgb(169, 251, 203);
    margin-right: 5px;
    margin-left: 5px;
}

.awk-color {
    width: 20px;
    height: 32px;
    background-color: rgb(252, 175, 74);
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
}

.op-color {
    width: 20px;
    height: 32px;
    background-color: #F5EA08;
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
}

.dl-text,
.sl-text,
.tts-text,
.op-text,
.awk-text {
    width: auto;
    display: inline-block;
    margin-right: 15px;
    font-size: 16px;
}

.key-text {
    margin-right: 10px;
    vertical-align: top;
    font-weight: bold;
    font-size: 16px;
}

.key-wrapper {
    background: #fff;
    height: 45px;
    margin: 1px 60px;
    border: 1px solid #a0a0a0;
    padding: 5px 10px;
}

.sleep-trend .reload {
    margin: auto 0 auto 40px;
    width: 33px;
    display: block;
}

.sleep-trend .reload img{
    width: 36px;
    cursor: pointer;
}
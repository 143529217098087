.cu-binding {

}

.cu-binding .esp-table.unbind-tbl {
    width: 860px;
}

.cu-binding .esp-table.unbind-tbl thead td {
    height: 60px;
    font-size: 20px;
}

.cu-binding .esp-table.unbind-tbl td {
    height: 43px;
    text-align: left;
    padding-left: 10px !important;
}

.cu-binding .esp-table.unbind-tbl td.max {
    width: 859px !important;
    max-width: 860px !important;;
}

.cu-binding .esp-table.unbind-tbl .esp-table-body {
    /*height: 58vh;*/
    font-size: 20px;
}

.cu-binding .esp-table.unbind-tbl td:nth-child(1) {
    width: 260px;
}

.cu-binding .esp-table.unbind-tbl td:nth-child(2) {
    width: 290px;
    text-transform: uppercase;
}

.cu-binding .esp-table.unbind-tbl td:nth-child(3) {
    width: 309px;
}

.cu-binding .esp-table.cu-name-tbl {
    width: 571px;
}

.cu-binding .esp-table.cu-name-tbl thead td {
    height: 60px;
    font-size: 20px;
}

.cu-binding .esp-table.cu-name-tbl td {
    height: 43px;
    text-align: left;
    /*padding-left: 10px !important;*/
}

.cu-binding .esp-table.cu-name-tbl td.max {
    width: 569px !important;
    max-width: 570px !important;;
}

.cu-binding .esp-table.cu-name-tbl .esp-table-body {
    /*height: 65vh; */
    font-size: 20px;
    /*max-height: 58vh;*/
}

.cu-binding .esp-table.cu-name-tbl td:nth-child(1) {
    width: 260px;
    padding-left: 10px !important;
}

.cu-binding .esp-table.cu-name-tbl td:nth-child(2) {
    width: 310px;
    text-align: center;
}

.cu-binding .delete-cu-modal .modal-content {
    width: 420px;
}

.cu-binding .delete-cu-modal .modal-content .mt10 {
    margin-top: 10px;
}

.cu-binding .delete-cu-modal .modal-content .m35 {
    margin-top: 25px;
}

.cu-binding .delete-cu-modal .modal-content .modal-header {
    height: 55px;
}

.cu-binding .delete-cu-modal .modal-content .modal-body {
    font-size: 20px;
    padding-left: 50px;
    min-height: 180px;
    line-height: 1.9;
}

.cu-binding .delete-cu-modal .modal-content .modal-body .dest {
    margin-top: 30px;
}

.cu-binding .delete-cu-modal .modal-content .modal-body .select-dest {
    margin-top: 30px;
}

.cu-binding .delete-cu-modal .iresp-dropdown {
    margin-left: 0;
}

.cu-binding .delete-cu-modal .iresp-dropdown .select {
    width: 320px;
    font-size: 16px;
    font-weight: normal;
}

.cu-binding .delete-cu-modal .iresp-dropdown select {
    font-size: 16px;
    font-weight: normal;
}

.cu-binding .delete-cu-modal .iresp-dropdown .select .selected {
    font-size: 16px;
    font-weight: normal;
}

.cu-binding .delete-cu-modal input[type=checkbox] {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-top: 2px;
}

.cu-binding .delete-cu-modal div.inline {
    width: 50%;
}

.cu-binding .delete-cu-modal .inline span {
    margin-left: 30px;
}

.cu-binding .delete-cu-modal .modal-content .modal-footer {
    font-size: 20px;
    height: 55px;
    min-height: 55px;
    padding: 0;
}

.cu-binding button.disabled {
    color: #aaaaaa;
    opacity: 1.0;
}

.cu-binding .data {
    padding: 5px 0;
    position: relative;
}

.cu-binding .tabs {
    /*border-bottom: 5px solid var(--main-color);*/
    margin-right: 20px;
    /*margin-bottom: 10px;*/
}

.cu-binding .devider {
    border-bottom: 7px solid var(--main-color);
    position: absolute;
    width: calc(100% - 0px);
    top: 34px;
    left: 0;
}

.cu-binding .tabs a {
    text-align: center;
    /*min-width: 165px;*/
    padding: 0 20px;
    display: inline-block;
    /*border-bottom: 5px solid black;*/
    text-decoration: none !important;
    border: 1px solid rgba(200, 200, 200, 1.0);
    border-bottom: 0;
    border-right: 0;
    border-radius: 8px 8px 0 0;
    color: black !important;
    cursor: pointer;
}

.cu-binding .tabs a:last-child {
    border-right: 1px solid rgba(200, 200, 200, 1.0);
}

.cu-binding .tabs a:hover {
    text-decoration: none;
}

.cu-binding .tabs a.selected {
    font-weight: bold;
    /*border: 1px solid var(--main-color);*/
    /*border-bottom: 5px solid rgba(1, 1, 1, 0);*/
    /*border-radius: 8px 8px 0 0;*/
    text-decoration: underline;
    background-color: var(--main-color);
    color: white !important;
}

.cu-binding .link {
    color: #007bff;
}

.cu-binding .link a {
    text-decoration: underline;
    cursor: pointer;
}

.cu-binding .link.disabled {
    text-decoration: underline;
    color: #AAAAAA;
    cursor: pointer;
    opacity: 1.0;
}

.cu-binding input {
    border: none !important;
    max-width: 247px;
}

.cu-binding .error {
    width: 570px;
    /*position: absolute;*/
    /*top: calc(65vh + 215px);*/
    /*left: 40px;*/
    top: 0;
    left: 0;
}

.cu-binding .add-row {
    width: 571px;
}

.cu-binding .add-row td {
    height: 43px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
}

.cu-binding .add-row td:nth-child(1) {
    width: 260px;
    padding-left: 10px !important;
}

.cu-binding .add-row td:nth-child(2) {
    width: 310px;
    text-align: center;
}

.cu-binding .esp-table-body {
    /*max-height: 610px !important;*/
}
@media only screen and (max-device-width: 500px) {
    .tabs a.selected {
        font-size: 17px;
        border: 1px solid var(--main-color);
    }

    .react-calendar__navigation__label__labelText {
        color: #000;
        font-size: 18px;
    }

    .react-calendar__navigation {
        height: 50px;
    }

    .react-calendar__month-view__days__day {
        color: #000 !important;
    }

    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
        color: #d10000 !important;
    }

    .iresp-dropdown .select {
        height: 36px;
    }

    .iresp-filter-date input {
        height: 36px;
    }

    .iresp-filter-date .input-group-custom {
        height: 33px;
    }
}

@media only screen and (max-width: 1366px){
    .zoom-3 {
        /*zoom: 0.8;*/
    }

    .zoom-2 {
        zoom: 0.95;
    }
}

@media only screen and (max-width: 1000px){
    .zoom-3 {
        /*zoom: 0.48;*/
    }

    .zoom-2 {
        /*zoom: 0.61;*/
    }
}


.thresholds {
    font-size: 20px;
}

.thresholds .esp-table.threshold-tbl {
    width: 1623px;
}

.thresholds .esp-table.threshold-tbl thead td {
    height: 40px;
}

.thresholds .esp-table.threshold-tbl td {
    height: 43px;
}

.thresholds .esp-table.threshold-tbl td.max {
    width: 1622px !important;
}

.thresholds .esp-table.threshold-tbl .esp-table-header {
    font-size: 20px;
}

.thresholds .esp-table.threshold-tbl .esp-table-body {
    font-size: 20px;
}

.thresholds .esp-table.threshold-tbl td.w1 {
    width: 332px;
    text-align: left;
    padding-left: 10px !important;
}

.thresholds .esp-table.threshold-tbl td.w2 {
    width: 137px;
    text-align: left;
    padding-left: 10px !important;
}

.thresholds .esp-table.threshold-tbl td.w3 {
    width: 60px;
}

.thresholds .esp-table.threshold-tbl td.w4 {
    width: 88px;
}

.thresholds .esp-table.threshold-tbl td.w5 {
    width: 88px;
}

.thresholds .esp-table.threshold-tbl td.w6 {
    width: 85px;
}

.thresholds .esp-table.threshold-tbl td.w7 {
    width: 92px;
    text-align: left;
    padding-left: 10px !important;
    word-break: break-word;
}

.thresholds .esp-table.threshold-tbl td.w8 {
    width: 90px;
    word-break: break-word;
    padding: 2px !important;
}

.thresholds .esp-table.threshold-tbl td.w9 {
    width: 100px;
    word-break: break-word;
}

.thresholds .link {
    cursor: pointer;
    text-decoration: underline;
}

.thresholds .edit-threshold-modal .modal-content {
    width: 725px;
    height: 875px;
    font-size: 20px;
}

@media only screen and (max-device-width: 500px) {
    .thresholds .edit-threshold-modal .modal-content {
        font-size: 16px !important;
    }
}

.thresholds .edit-threshold-modal .modal-dialog {
    margin: 2% 34%;
}


@media only screen and (min-device-width: 980px) {
    .thresholds .edit-threshold-modal .modal-dialog {
        margin: 2% 34%;
    }
}

@media only screen and (min-device-width: 1400px) {
    .thresholds .edit-threshold-modal .modal-dialog {
        margin: 2% 34%;
    }
}

.thresholds .edit-threshold-modal .modal-body {
    padding: 10px 40px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.thresholds .edit-threshold-modal .modal-header {
    height: 70px;
}

.thresholds .edit-threshold-modal .modal-footer {
    height: 60px;
    min-height: 60px;
    padding: 0;
}

.thresholds .mt5 {
    margin-top: 5px;
}

.thresholds .mt10 {
    margin-top: 15px;
}

.thresholds .mt20 {
    margin-top: 40px;
}

.thresholds .ml10 {
    margin-left: 35px;
}

.thresholds .mr60 {
    margin-right: 60px;
}

.thresholds .mr40 {
    margin-right: 40px;
}


.thresholds .mr60 .esp-select__single-value {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: rgb(20, 100, 246) !important;
}

.thresholds .mr40 .esp-select__single-value {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: rgb(20, 100, 246) !important;
}

.thresholds .modal-header {
    display: flex;
    height: 80px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.thresholds .white-area {
    width: 643px;
    height: 90px;
    /*height: 120px;*/
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #AAAAAA;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.thresholds .white-area.h150 {
    height: 180px !important;
}

.thresholds .white-area.h200 {
    height: 200px !important;
}

.thresholds .one-line {
    display: flex;
    height: 50px;
}

.thresholds .one-line-2 {
    display: flex;
    height: 35px;
}

.thresholds .one-line .esp-select {
    width: 150px;
}

.thresholds .one-line .esp-select {
    margin-left: 0;
}

.thresholds .half-line-wrapper {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #AAAAAA;
    padding: 10px 5px 5px 5px;
    flex-direction: column;
    height: 173px;
}

.thresholds .half-line-wrapper-2 {
    padding: 0 15px 10px 10px;
}

.thresholds .half-line-wrapper-3 {
    display: flex;
}

.thresholds .w-space {
    width: 4%;
}

.thresholds .w50p {
    width: 48%;
}

.thresholds .h70 {
    height: 64px;
    /*height: 60px;*/
}

.thresholds .one-line > div {

}

.thresholds .one-line input {
    text-align: center;
    font-weight: bold;
    color: rgb(20, 100, 246) !important;
}

.thresholds .w40 {
    width: 50px !important;
}

.thresholds .w60 {
    width: 60px !important;
}

.thresholds .w90 {
    width: 90px !important;
}

.thresholds .ml5 {
    margin-left: 5px;
}

.thresholds .ml6 {
    margin-left: 10px;
}

.thresholds .mtmb10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.thresholds .w120 {
    width: 122px !important;
}

.thresholds .flex-right {
    margin-left: auto;
    margin-right: 50px;
}

.custom-control-label {
    cursor: pointer;
}

.custom-control-label::before {
    width: calc(3rem - 4px) !important;
    height: calc(2.1rem - 7px) !important;
    border-radius: 0.5rem !important;
}

.custom-control-label::after {
    width: calc(1.5rem) !important;
    height: calc(1.5rem) !important;
    left: -2.25rem !important;
    top: 0.2rem !important;
    border: 1px solid #AAAAAA !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    left: -1.65rem !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: rgb(20, 100, 246) !important;
    background-color: rgb(20, 100, 246) !important;
}

.thresholds .descr {
    font-size: 16px;
    margin-top: -8px;
}

.thresholds .h110 {
    height: 110px;
    padding: 10px 20px;
}

.thresholds .w100 {
    width: 100px !important;
}

.thresholds .w220 {
    width: 225px !important;
}

.thresholds .w222 {
    width: 222px !important;
}

.thresholds .w371 {
    width: 371px !important;
}

.thresholds .mb5 {
    margin-bottom: 5px;
}

.thresholds .ml25 {
    margin-left: 25px;
}

.thresholds .forNone {
    font-style: italic;
    font-size: 16px;
    color: #AAAAAA;
    margin-left: 20px;
    margin-top: 4px;
}

.thresholds .highlight {
    background-color: #FFFFC3;
}

.thresholds .highlight-select .esp-select__control {
    background-color: #FFFFC3;
}

.thresholds .reset-to-default {
    text-align: right;
    color: rgb(20, 100, 246) !important;
    text-decoration: underline;
    cursor: pointer;
}
.one-line {
    display: flex;
    height: 80px;
}

.one-line > div:nth-child(1) {
    width: 190px;
}

.enroll-patient.ja .one-line > div:nth-child(1) {
    width: 300px;
}

.one-line .enroll-input {
    margin-left: 20px;
    height: 35px;
    width: 425px;
    padding: 10px;
}

.one-line .enroll-input-sm {
    margin-left: 20px;
    margin-right: 20px;
    height: 35px;
    width: 360px;
    padding: 10px;
}

.one-line input::placeholder {
    font-size: 20px;
}

.one-line img {
    width: 45px;
    height: 51px;
    margin-top: -10px;
    cursor: pointer;
}

.one-line .link {
    text-decoration: underline;
    color: #007bff;
    cursor: pointer;
}

.one-line .link.disabled {
    text-decoration: underline;
    color: #AAAAAA;
    cursor: pointer;
    opacity: 1.0;
}

.one-line .esp-select {
    width: 425px;
    height: 35px;
    margin-left: 20px;
}

.enroll-patient .iresp-modal .modal-content {
    min-height: 240px;
}

.enroll-patient .iresp-modal .modal-body {
    min-height: 100px;
}

.enroll-patient .error {
    width: 720px;
}

.enroll-patient .iresp-modal .modal-content {
    width: 360px;
}
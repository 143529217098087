.patient-transfer {

}

.patient-transfer .esp-table.transfer-history {
    width: 1008px;
}

.patient-transfer .esp-table.transfer-history thead td {
    height: 60px;
}

.patient-transfer .esp-table.transfer-history td {
    height: 43px;
    cursor: auto;
}

.patient-transfer .esp-table.transfer-history td.max {
    width: 1007px !important;
    max-width: 1320px !important;;
}

.patient-transfer .esp-table.transfer-history .esp-table-body {
    /*height: 58vh;*/
}

.patient-transfer .esp-table.transfer-history td:nth-child(1){
    width: 262px;
}

.patient-transfer .esp-table.transfer-history td:nth-child(2){
    width: 183px;
    text-align: center;
    padding-left: 0 !important;
}

.patient-transfer .esp-table.transfer-history td:nth-child(3){
    width: 163px;
    text-align: center;
    padding-left: 0 !important;
}

.patient-transfer .esp-table.transfer-history td:nth-child(4){
    width: 200px;
}

.patient-transfer .esp-table.transfer-history td:nth-child(5){
    width: 199px;
}

.patient-transfer .esp-table.patient-list {
    width: 945px;
}

.patient-transfer .esp-table.patient-list thead td {
    height: 60px;
}

.patient-transfer .esp-table.patient-list td {
    height: 43px;
}

.patient-transfer .esp-table.patient-list td.max {
    width: 944px !important;
    max-width: 945px !important;;
}

.patient-transfer .esp-table.patient-list .esp-table-body {
    /*height: 58vh;*/
}

.patient-transfer .esp-table.patient-list td:nth-child(1){
    width: 485px;
}

.patient-transfer .esp-table.patient-list td:nth-child(2){
    width: 200px;
}

.patient-transfer .esp-table.patient-list td:nth-child(3){
    width: 259px;
}

.patient-transfer .transfer-request-modal .modal-content {
    width: 420px;
}

.patient-transfer .transfer-request-modal .modal-content .m35 {
    margin-top: 25px;
}

.patient-transfer .transfer-request-modal .modal-content .modal-header {
    height: 55px;
}

.patient-transfer .transfer-request-modal .modal-content .modal-body {
    font-size: 16px;
    padding-left: 50px;
    min-height: 300px;
}

.patient-transfer .transfer-request-modal .modal-content .modal-body .dest{
    margin-top: 30px;
}

.patient-transfer .transfer-request-modal .modal-content .modal-body .select-dest{
    margin-top: 30px;
}

.patient-transfer .transfer-request-modal .iresp-dropdown {
    margin-left: 0;
}

.patient-transfer .transfer-request-modal .iresp-dropdown .select {
    width: 320px;
    font-size: 16px;
    font-weight: normal;
}

.patient-transfer .transfer-request-modal .iresp-dropdown select {
    font-size: 16px;
    font-weight: normal;
}

.patient-transfer .transfer-request-modal .iresp-dropdown .select .selected{
    font-size: 16px;
    font-weight: normal;
}

.patient-transfer .transfer-request-modal input[type=checkbox] {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-top: 2px;
}

.patient-transfer .transfer-request-modal div.inline {
    width: 50%;
}

.patient-transfer .transfer-request-modal .inline span {
    margin-left: 30px;
}

.patient-transfer .transfer-request-modal .modal-content .modal-footer {
    font-size: 16px;
    height: 55px;
    min-height: 55px;
    padding: 0;
}

.patient-transfer button.disabled {
    color: #aaaaaa;
    opacity: 1.0;
}

.patient-transfer .error div {
     min-width: 338px;
 }

.patient-transfer .discharge-modal .modal-content {
    width: 420px;
    height: 425px;
    box-shadow: 5px 5px 10px #888888;
}

.patient-transfer .discharge-modal .modal-content .modal-header {
    height: 55px;
}

.patient-transfer .discharge-modal .modal-content .modal-body {
    font-size: 16px;
    padding-left: 50px;
}

.patient-transfer .discharge-modal .modal-content .mt10 {
    margin-top: 10px;
}

.patient-transfer .discharge-modal .modal-content .mb10 {
    margin-bottom: 10px;
}

.patient-transfer .discharge-modal .modal-content .red,
.patient-transfer .discharge-reconfirm-modal .modal-content .red {
    color: #E61610 !important;
    font-weight: bold;
}

.patient-transfer .discharge-modal .modal-content .m35 {
    margin-top: 35px;
}

.patient-transfer .discharge-modal .modal-content .modal-footer {
    font-size: 16px;
    height: 55px;
    min-height: 55px;
    padding: 0;
}

.patient-transfer .discharge-reconfirm-modal .modal-content {
    width: 420px;
    min-height: 50px;
    top: 255px;
}

.patient-transfer .discharge-reconfirm-modal .modal-content .modal-header {
    height: 55px;
}

.patient-transfer .discharge-reconfirm-modal .modal-content .modal-body {
    font-size: 16px;
    padding-left: 50px;
    min-height: 50px;
}

.patient-transfer .discharge-reconfirm-modal .modal-content .modal-footer {
    font-size: 16px;
    height: 55px;
    min-height: 55px;
    padding: 0;
}

.patient-transfer .edit-modal .modal-content {
    width: 665px;
    box-shadow: 5px 5px 10px #888888;
}

.patient-transfer .edit-modal .modal-content .modal-header {
    height: 65px;
}

.patient-transfer .edit-modal .modal-content .modal-body {
    font-size: 20px;
    padding: 10px 36px;
    height: 260px;
}

.patient-transfer .edit-modal .modal-content .modal-body input.enroll-input {
    max-width: 345px;
}

.patient-transfer .edit-modal .modal-content .modal-body input.enroll-input-sm {
    max-width: 280px;
}

.patient-transfer .edit-modal .modal-content .modal-body .w330 {
    width: 345px !important;
    max-width: 345px !important;
}

.patient-transfer .edit-modal .modal-content .mt10 {
    margin-top: 10px;
}

.patient-transfer .edit-modal .modal-content .mb10 {
    margin-bottom: 10px;
}

.patient-transfer .edit-modal .modal-content .red {
    color: #E61610 !important;
    font-weight: bold;
}

.patient-transfer .edit-modal .modal-content .m35 {
    margin-top: 25px;
}

.patient-transfer .edit-modal .modal-content .modal-footer {
    font-size: 20px;
    height: 65px;
    min-height: 55px;
    padding: 0;
}

.patient-transfer .edit-modal .modal-footer button {
    margin: 10px 70px;
}

.patient-transfer .one-line > div:nth-child(1) {
    max-width: 205px;
    width: 160px;
}

.patient-transfer.ja .one-line > div:nth-child(1) {
    width: 205px;
}

.patient-transfer .inline {
    display: inline-block;
}

.patient-transfer .csv-archive {
    width: 580px;
    margin-left: 200px;
    vertical-align: top;
}

.patient-transfer .csv-archive-table {
    width: 581px;
}

.patient-transfer .csv-archive-table thead td {
    height: 60px;
}

.patient-transfer .csv-archive-table td {
    height: 43px;
}

.patient-transfer .csv-archive-table td.max {
    width: 580px !important;
}

.patient-transfer .csv-archive-table td:nth-child(1){
    width: 180px;
}

.patient-transfer .csv-archive-table td:nth-child(2){
    width: 200px;
}

.patient-transfer .csv-archive-table td:nth-child(3){
    width: 200px;
}

.patient-transfer .table-content {
    margin-top: 30px;
}
.logout-modal.esp-modal .modal-dialog {
    /*margin: 20% 26%;*/
    margin-top: 15%;
    font-size: 16px;
    margin-left: 42%;
    z-index: 2;
}

.logout-modal.esp-modal .modal-content {
    width: 350px;
    box-shadow: 5px 5px 10px #888888;
    min-height: 67px;
}

.logout-modal.esp-modal .modal-header {
    height: 47px;
    padding: 5px;
}

.logout-modal.esp-modal .modal-body {
    min-height: 67px;
    text-align: center;
}

.logout-modal.esp-modal .modal-content button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.logout-modal.esp-modal .modal-content .modal-footer {
    text-align: center;
    height: 40px;
    padding: 5px;
}

.already-open-modal.esp-modal .modal-dialog {
    /*margin: 20% 26%;*/
    margin-top: 15%;
    font-size: 16px;
    margin-left: 42%;
    z-index: 2;
}

.already-open-modal.esp-modal .modal-content {
    width: 360px;
    box-shadow: 5px 5px 10px #888888;
    min-height: 67px;
}

.already-open-modal.esp-modal .modal-header {
    height: 47px;
    padding: 5px;
}

.already-open-modal.esp-modal .modal-body {
    min-height: 67px;
    text-align: left;
    padding: 5px;
}

.already-open-modal.esp-modal .modal-content button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.already-open-modal.esp-modal .modal-content .modal-footer {
    text-align: center;
    height: 40px;
    padding: 5px;
}

.retry-modal.esp-modal .modal-dialog {
    /*margin: 20% 26%;*/
    margin-top: 15%;
    font-size: 16px;
    margin-left: 42%;
}

.retry-modal.esp-modal .modal-content {
    width: 293px;
    box-shadow: 5px 5px 10px #888888;
    min-height: 67px;
}

.retry-modal.esp-modal .modal-header {
    height: 47px;
    padding: 5px;
}

.retry-modal.esp-modal .modal-header .modal-title {
    height: 47px;
    padding: 5px;
    color: #E61610;
}

.retry-modal.esp-modal .modal-body {
    min-height: 67px;
    text-align: center;
    padding: 10px 5px;
    color: #E61610;
}

.retry-modal.esp-modal .modal-content button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.retry-modal.esp-modal .modal-content .modal-footer {
    text-align: center;
    height: 40px;
    padding: 0 0 5px 0;
}

.about-modal.iresp-modal {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.about-modal.iresp-modal .modal-content {
    font-size: 16px;
    min-height: 183px !important;
    box-shadow: 5px 5px 10px #888888;
    border: 2px solid #888;
    width: 345px;
}

.about-modal.iresp-modal .modal-body {
    /*min-height: 183px;*/
    text-align: left;
}

.about-modal.iresp-modal .modal-footer {
    padding: 0;
    min-height: 55px;
    height: 55px;
    font-size: 20px;
}

.about-modal.iresp-modal .modal-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 0 10px;
}

.about-modal.iresp-modal .about-versions {
    margin: 25px 0 0 10px;
}

.about-modal.iresp-modal p {
    margin: 0;
}

.about-modal.iresp-modal .modal-content .modal-footer button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.about-modal-4P.iresp-modal {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.about-modal-4P.iresp-modal .modal-dialog {
    margin: 14% 38%;
}

.about-modal-4P.iresp-modal .modal-content {
    font-size: 16px;
    min-height: 313px !important;
    box-shadow: 5px 5px 10px #888888;
    border: 2px solid #888;
    width: 550px;
}

.about-modal-4P.iresp-modal .modal-body {
    min-height: 230px;
    text-align: left;
}

.about-modal-4P.iresp-modal .modal-footer {
    padding: 0;
    min-height: 55px;
    height: 55px;
    font-size: 20px;
}

.about-modal-4P.iresp-modal .modal-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 0 10px;
}

.about-modal-4P.iresp-modal .about-versions {
    margin: 25px 0 0 10px;
}

.about-modal-4P.iresp-modal p {
    margin: 0;
}

.about-modal-4P.iresp-modal .modal-content .modal-footer button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
}

.about-modal-4P.iresp-modal .modal-content .inline.w-50 {
    padding: 0;
}

.line-height-1-3 {
    line-height:  1.3;
    margin: 6px 0;
    padding: 5px 5px 5px 10px !important;
}

.reload-to-new-version-modal.esp-modal .modal-dialog {
    margin: 12% auto 0;
}

.reload-to-new-version-modal.esp-modal .modal-body {
    height: 160px;
    min-height: 140px;
    font-size: 20px;
}

.reload-to-new-version-modal.esp-modal .modal-body p {
    margin-right: 20px;
}

.reload-to-new-version-modal.esp-modal .modal-content {
    width: 410px;
    box-shadow: 5px 5px 10px #888888;
    min-height: 140px;
}

.reload-to-new-version-modal.esp-modal .modal-header {
    height: auto;
    padding: 10px 5px 5px 5px;
}

.reload-to-new-version-modal.esp-modal .modal-content button {
    background-color: white;
    border: 0;
    color: #007bff;
    text-decoration: underline;
    font-size: 20px;
    margin: 5px 10px;
    width: 150px;
}

.reload-to-new-version-modal.esp-modal .modal-content .modal-footer {
    text-align: center;
    height: 55px;
    padding: 5px 0 5px 0;
}

.accept-policy {
    z-index: 2;
}

.accept-policy .modal-dialog{
    margin: auto;
    width: 230mm;
    max-width: 230mm;
    z-index: 99;
}

.accept-policy .modal-content {
    width: 230mm;
    height: 800px;
    padding-top: 10px;
}

.accept-policy .modal-header {
    display: none;
}

.accept-policy .modal-footer button.disabled {
    color: #444;
}

.accept-policy .modal-body {
    background-color: #fff;
    margin: auto 90px;
    padding: 10px 0;
    overflow-y: scroll;
    user-select: none;
}

.accept-policy .modal-footer {
    font-size: 20px;
}

.accept-policy iframe {
    width: 175mm;
    min-height: 700px;
    height: 6470px;
    margin: auto auto;
    border: none;
    overflow-y: visible;
    pointer-events: auto;
    user-select: none;
}

.accept-policy-jp iframe {
    height: 7450px;
}

.agree-group {
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: -50px;
}

.agree-chk {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.agree-label {
    font-size: 12pt;
    margin-left: 5px;
}

@media only screen and (min-device-width: 980px) {
    .accept-policy {
        zoom: 0.7;
    }

    .accept-policy .modal-body {
        margin: auto 60px;
    }

    .accept-policy .modal-body iframe {
        width: 248mm;
    }

    .accept-policy .modal-content {
        width: 287mm;
    }

    .accept-policy .modal-dialog {
        max-width: 287mm;
        width: 287mm;
    }
}

@media only screen and (min-device-width: 1366px) {
    .accept-policy {
        zoom: 0.9;
    }

    .accept-policy .modal-body {
        margin: auto 70px;
    }

    .accept-policy .modal-body iframe {
        width: 175mm;
    }

    .accept-policy .modal-content {
        width: 230mm;
    }

    .accept-policy .modal-dialog {
        max-width: 230mm;
        width: 230mm;
    }
}

@media only screen and (min-device-width: 1400px) {
    .accept-policy {
        zoom: 1;
    }

    .accept-policy .modal-body {
        margin: auto 90px;
    }

    .accept-policy .modal-body iframe {
        width: 175mm;
    }

    .accept-policy .modal-content {
        width: 230mm;
    }

    .accept-policy .modal-dialog {
        max-width: 230mm;
        width: 230mm;
    }
}
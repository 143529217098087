/* The container must be positioned relative: */
.iresp-sort {
    font-size: 16px;
    position: absolute;
    display: inline-block;
    right: 5px;
    top: 10px;
}

.iresp-sort .none-sort{
    visibility: hidden;
    opacity: 0.6;
}

.iresp-sort svg {
    color: #666666;
    cursor: pointer;
}
.esp-table {
    width: 685px;
}

.esp-table input.editing {
    margin: 5px 5px 5px 0;
    padding: 0 0 0 3px;
    width: 90%;
}

.esp-table a.editing,
.esp-table span.editing {
    color: #FE7069;
}

.esp-table td {
    padding: 2px 0 !important;
    position: relative;
    word-wrap: break-word;
    word-break: break-all;
}

.esp-table .esp-table-header td {
    user-select: none;
}

.esp-table .checkbox:disabled {
    border: 1px solid #B8B9B2;
    -webkit-appearance: none;
    background-color: #fff;
    color: #B8B9B2 !important;
    width: 13px;
    height: 14px;
    position: relative;
    cursor: default;
    margin: 0 3px;
}

.esp-table .checkbox {
    border: 1px solid #FE7069;
    border-radius: 2px;
    -webkit-appearance: none;
    background-color: #fff;
    color: #B8B9B2 !important;
    width: 14px;
    height: 14px;
    position: relative;
    cursor: pointer;
    margin: 0 3px;
    vertical-align: middle;
}

.esp-table .checkbox:disabled:checked:after {
    content: '\2714';
    font-size: 16px;
    position: absolute;
    top: -4px;
    left: 0;
    color: #B8B9B2;
}

.esp-table .checkbox:checked:after {
    content: '\2714';
    font-size: 16px;
    position: absolute;
    top: -4px;
    left: 0;
    color: #FE7069;
}

.esp-table .label {
    display: inline-block;
    vertical-align: top;
}

.esp-table-header {
    background: #E5E5E5;
    /*background: red;*/
    color: black;
    font-weight: bold;
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
}

.esp-table-body {
    font-size: 16px;
    border-top: none;
    background: #fff;
    color: black;
    width: calc(100% + 17px);
    overflow-y: overlay;
    max-height: 650px !important;
}

@supports (-ms-ime-align:auto) {
    .esp-table-body {
        width: calc(100% + 15px) !important;
    }
}

@media only screen and (min-device-width: 400px) {
    .esp-table-body {
        max-height: 650px !important;
    }

    .esp-table-body {
        width: calc(100% + 5px);
    }
}

@media only screen and (min-device-width: 980px) {
    .esp-table-body {
        max-height: 520px !important;
    }

    .esp-table-body {
        width: calc(100% + 25px);
    }
}

@media only screen and (min-device-width: 1300px) {
    .esp-table-body {
        max-height: 600px !important;
    }
    .esp-table-body {
        width: calc(100% + var(--scrollbar-width));
    }
}


@media only screen and (min-device-width: 1700px) {
    .esp-table-body {
        max-height: 570px !important;
    }
}

.esp-table-header td {
    border: 1px solid black;
    text-align: center;
}

.esp-table-header td {
    border-bottom: none;
}

.esp-table-body td {
    border: 1px solid black;
    text-align: center;
}

.table-error {
    color: #e61610;
    margin: 0;
    font-style: italic;
    width: 100%;
    /*border: 1px dashed #e61610;*/
    padding: 2px 5px 2px 5px;
}

.table-error-white {
    color: #e61610;
    opacity: 0;
    margin: 0;
    font-style: italic;
    width: 100%;
    border: none;
    padding: 2px 5px 2px 5px;
}
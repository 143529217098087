.tiles .tile {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    position: relative;
    /*border: 3px solid rgb(41, 171, 224);*/
    width: calc(var(--tile-width) - 2px);
    height: calc(var(--tile-height) - 1px);
    /*font-size: 2vh;*/
    border-radius: 10px;
    margin: 1px;
    /*cursor: pointer;*/
}

.tiles .tile .patient-name {
    height: 14.5%;
    color: #fff;
    background-color: #444444;
    width: 100%;
    border-radius: 5px 8px 0 0;
    text-align: left;
    align-items: center;
    display: flex;
    font-size: calc(var(--tile-height) / 12);
    font-weight: bold;
}

.tiles .tile .patient-name span {
    margin: 0 0 0 15px;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    /*text-overflow: ellipsis;*/
    padding: 10px 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}

.tiles .tile .status {
    height: 23%;
    display: flex;
    align-items: center;
}

.tiles .tile .status > div {
    display: flex;
    width: 50%;
    justify-items: center;
    align-items: center;
    padding: 0;
    margin: 0 20px;
    border-radius: 5px;
    height: 94%;
}

.tiles .tile .status > div:nth-child(1) {
    border: 1px solid #F6991E;
    margin: 5px 3px 0 5px;
}

.tiles .tile .status .status-resp div{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tiles .tile .status > div:nth-child(2) {
    border: 1px solid #62F583;
    margin: 5px 5px 0 3px;
}

.tiles .tile .status .status-pr div{
    text-align: center;
    width: 50%;
}


.tiles .tile .status img {
    width: calc(var(--tile-height) / 8);
    /*margin-left: 15px;*/
}

.tiles .tile .status .status-value-pr {
    font-size: calc(var(--tile-height) / 8);
    color: rgb(98, 245, 131);
}

.tiles .tile .status .status-value-resp {
    font-size: calc(var(--tile-height) / 8);
    color: rgb(246, 153, 30);
}

.tiles .tile .status .status-value-pr,
.tiles .tile .status .status-value-resp {
    font-family: "Roboto", sans-serif !important;
    font-weight: 600;
}

.tiles .tile .icons {
    height: 62.5%;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-items: flex-end;*/
    /* width: 100%; */
    /*flex-direction: column;*/
    border: 1px solid #A0A0A0;
    border-radius: 5px;
    margin: 5px 5px;
    position: relative;
}

.icons.disconnected {
    /*width: 98%;*/
    margin: 5px !important;
    height: 85.5% !important;
}

.icons.no-data {
    height: 85.5% !important;
}

.tiles .tile .icons.no-data .icons-wrapper {
    height: 88% !important;
}

.tiles .tile .icons.no-data .icons-wrapper img{
    height: calc((var(--tile-height) + var(--tile-width-px)) / 12);
}

.tiles .tile .icons.border-sleeping {
    border: 1px solid #6AE1FF !important;
}

.tiles .tile .icons.border-absent {
    border: 1px solid #F5EA08 !important;
}

.tiles .tile .icons.border-awake {
    border: 1px solid #F6991E !important;
}

.tiles .tile .icons.border-eol {
    border: 1px solid #E61610 !important;
}

.tiles .tile .icons.border-sleeping .bed-name {
    background-color: #6AE1FF !important;
}

.tiles .tile .icons.border-absent .bed-name {
    background-color: #F5EA08 !important;
}

.tiles .tile .icons.border-awake .bed-name {
    background-color: #F6991E !important;
}

.tiles .tile .icons.border-eol .bed-name {
    background-color: #E61610 !important;
    color: #fff;
}

.tiles .tile.sound-icon-trigger .icons.bed-e1 {
    border: 1px solid #E61610 !important;
}

.tiles .tile.sound-icon-trigger .icons.bed-e1 .bed-name {
    background-color: #E61610 !important;
    color: #fff;
}

.tiles .tile .icons .icons-wrapper {
    width: 100%;
    margin: auto auto;
    height: calc(100% - (var(--tile-height) / 9.7)) !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-items: center;
}

.tiles .tile .icons.disconnected .icons-wrapper {
    /*margin: calc(var(--tile-height)/5) !important;*/
}

.icons.updating {
    /*width: 98%;*/
    margin: 5px !important;
    height: 85% !important;
}

.tiles .tile .icons.updating .icons-wrapper {
     height: calc(83% - 8.5px) !important;
}

.tiles .tile .icons.disconnected .icons-wrapper img{
    height: calc((var(--tile-height) + var(--tile-width-px)) / 6);
}

.tiles .tile .icons.updating .icons-wrapper img{
    height: calc((var(--tile-height) + var(--tile-width-px)) / 12);
}


.tiles .tile .icons .bed-name {
    width: 100%;
    background-color: #a0a0a0;
    color: #000;
    font-size: calc(var(--tile-height)/30 + var(--tile-width-px)/30);
    font-weight: 700;
    bottom: 0;
    text-align: center;
    padding: calc(var(--tile-height)/25) 0 calc(var(--tile-height)/34) 0;
    height: calc((var(--tile-height) / 9.7) + 0.5px);
    border-radius: 0 0 2px 2px;
}

.tiles .tile .icons.updating .bed-name {
    height: calc((var(--tile-height) / 9.7) + 3.5px);
}

.tiles .tile .icons.updating .bed-name.n-beds-8 {
    height: calc((var(--tile-height) / 9.7) + 4px);
}

.tiles .tile .icons .bed-name span{
    vertical-align: middle;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tiles .tile .icons .left-icon,
.tiles .tile .icons .right-icon {
    width: 50%;
    justify-items: center;
    align-items: center;
    padding: 0;
    display: flex;
}

.tiles .tile .icons img {
    width: auto;
    height: calc(var(--tile-height) / 2.5);
    margin: auto auto;
}

.tiles .tile .icons.updating img {
    width: auto;
    height: calc((var(--tile-height) + var(--tile-width-px)) / 9);
    margin: auto auto;
}

/*@media only screen and (max-device-width: 1400px) {*/
    /*.tiles .tile .icons img {*/
        /*width: auto;*/
        /*height: calc(var(--tile-height) / 2.5);*/
        /*margin: auto auto;*/
    /*}*/
/*}*/

/*@media only screen and (max-device-width: 980px) {*/
    /*.tiles .tile .icons img {*/
        /*width: auto;*/
        /*height: calc(var(--tile-height) / 3.5);*/
        /*margin: auto auto;*/
    /*}*/
/*}*/

/*@media only screen and (max-device-width: 400px) {*/
    /*.tiles .tile .icons img {*/
        /*width: auto;*/
        /*height: calc(var(--tile-height) / 4.5);*/
        /*margin: auto auto;*/
    /*}*/
/*}*/

.tiles .tile .beds {
    height: 11%;
    display: flex;
    font-size: calc((var(--tile-height) + var(--tile-width-px)) / 30);
}

.tiles .tile .beds div:nth-child(1) {
    border-radius: 0 0 0 5px;
    border-right: 1px solid #000;
}

.tiles .tile .beds div:nth-child(2) {
    border-radius: 0 0 5px 0;
    border-left: 1px solid #000;
}

.tiles .tile .beds div {
    width: 50%;
    background-color: rgb(41, 171, 224);
    color: white;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.tiles .tile .beds span {
    margin: auto;
}

.live-tile .status-img-pr {
    max-width: 50%;
}

.live-tile .status-img-resp {
    max-width: 50%;
}

.live-tile .txt-lg-error {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc((var(--tile-height) + var(--tile-width-px)) / 18);
    font-weight: bold;
    margin: auto auto;
}

.live-tile .img-error {
    display: flex;
    justify-content: center;
    width: 40% !important;
    margin: auto auto;
}

.live-tile .banner-error {
    /*border-color: red;*/
}

.live-tile .banner-error .patient-name-red {
    background-color: red;
    color: white;
    cursor: pointer;
    border-color: red;
}

.live-tile .banner-error .patient-name-white {
    background-color: #fff;
    color: rgb(41, 171, 224);
    cursor: pointer;
    border-color: rgb(41, 171, 224);
}

.live-tile .sound-icon .patient-name {
    background-image: url('../../../images/Speakers-1A-Blue-29abe0.png');
    background-repeat: no-repeat;
    background-size: calc((var(--tile-height) + var(--tile-width-px)) / 3 * 0.1);
    background-position: 97% 50%;
    background-color: #fff;
}

.live-tile .sound-icon-cleared .patient-name {
    background-image: url('../../../images/sound_muted_blue.png');
    background-repeat: no-repeat;
    background-size: calc((var(--tile-height) + var(--tile-width-px)) / 3 * 0.1);
    background-position: 97% 50%;
    /*background-color: red;*/
    /*border-color: red;*/
    /*color: white;*/
    background-color: #fff;
    color: rgb(41, 171, 224);
}

.live-tile .sound-icon-trigger {
    cursor: pointer;
}

.live-tile .sound-icon-trigger-red {
    /*background-image: url('../../../images/Speakers-1A-White.png');*/
    background-repeat: no-repeat;
    background-size: calc((var(--tile-height) + var(--tile-width-px)) / 3 * 0.1);
    background-position: 97% 50%;
    background-color: red !important;
    border-color: red !important;
    color: white !important;
}

.live-tile .sound-icon-trigger-white {
    /*background-image: url('../../../images/Speakers-1A-Blue-29abe0.png');*/
    background-repeat: no-repeat;
    background-size: calc((var(--tile-height) + var(--tile-width-px)) / 3 * 0.1);
    background-position: 97% 50%;
    background-color: #fff;
}

.live-tile .sound-icon-mute .patient-name {
    background-image: url('../../../images/sound_muted_blue.png');
    background-repeat: no-repeat;
    background-size: calc((var(--tile-height) + var(--tile-width-px)) / 3 * 0.1);
    background-position: 97% 50%;
    cursor: auto;
    background-color: #fff;
}

.live-tile .sound-icon-mute-trigger .patient-name {
    background-image: url('../../../images/sound_muted_white.png');
    background-repeat: no-repeat;
    background-size: calc((var(--tile-height) + var(--tile-width-px)) / 3 * 0.1);
    background-position: 97% 50%;
    cursor: auto;
    background-color: red;
    border-color: red;
    color: white;
}

/*.live-tile .banner-error .sound-icon-mute-red {*/
/*background-image: url('../../../images/sound_muted_white.png');*/
/*background-repeat: no-repeat;*/
/*background-size: calc((var(--tile-height) + var(--tile-width-px)) / 3 * 0.1);*/
/*background-position: 97% 50%;*/
/*cursor: auto;*/
/*}*/

/*.live-tile .banner-error .sound-icon-mute-white {*/
/*background-image: url('../../../images/sound_muted_blue.png');*/
/*background-repeat: no-repeat;*/
/*background-size: calc((var(--tile-height) + var(--tile-width-px)) / 3 * 0.1);*/
/*background-position: 97% 50%;*/
/*cursor: auto;*/
/*}*/

.live-tile .not-install {
    width: auto !important;
    /*margin-left: 32%;*/
    height: calc((var(--tile-height) + var(--tile-width-px)) / 10) !important;
}

.live-tile .spash-big {
    font-size: calc((var(--tile-height) + var(--tile-width-px)) / 10);
}

.live-tile .tile-spinner {
    width: calc((var(--tile-height) + var(--tile-width-px)) / 20) !important;
    height: calc((var(--tile-height) + var(--tile-width-px)) / 20) !important;
}

.live-tile .tile-spinner-2 {
    width: calc((var(--tile-height) + var(--tile-width-px)) / 12) !important;
    height: calc((var(--tile-height) + var(--tile-width-px)) / 12) !important;
}

.live-tile .left-icon .awake {
    position: relative;
    margin: auto;
    width: 80%;
}

.live-tile .left-icon .awake img {
    width: 100%;
}

.live-tile .left-icon .awake img:nth-child(2) {
    position: absolute;
    left: 0;
    top: 0;
    animation: fadein 1000ms infinite;
}

.live-tile .left-icon .pos-status-group {
    position: relative;
    margin: auto;
    width: 80%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.live-tile .pos-status-group {
    width: 100%;
}

.live-tile .left-icon .pos-status-group img {
    width: auto;
}

/*.live-tile .left-icon .pos-status-group img:nth-child(2) {*/
/*position: absolute;*/
/*left: 0;*/
/*top: 0;*/
/*animation: fadein 1000ms infinite;*/
/*}*/

.live-tile .left-icon .pos-status-group img.status-awake {
    animation: fadein 1000ms infinite;
}

.live-tile .left-icon .pos-status-group img.status-sleeping,
.live-tile .left-icon .pos-status-group img.lying-judgement,
.live-tile .left-icon .pos-status-group img.status-judgment {
    width: calc((var(--tile-height) + var(--tile-width-px)) / 25);
    height: calc((var(--tile-height) + var(--tile-width-px)) / 25);
}

.live-tile .left-icon .pos-status-group img.status-sleeping {
    top: calc((var(--tile-height) + var(--tile-width-px)) / 50);
    left: calc((var(--tile-height) + var(--tile-width-px)) / 40);
}

.live-tile .left-icon .pos-status-group img.status-judgment {
    top: calc((var(--tile-height) + var(--tile-width-px)) / 70);
    left: auto;
    right: calc((var(--tile-height) + var(--tile-width-px)) / 70);
}

.live-tile .left-icon .pos-status-group img.lying-judgement {
    top: calc((var(--tile-height) + var(--tile-width-px)) / 12);
    left: calc((var(--tile-height) + var(--tile-width-px)) / 33);
    right: auto;
    position: absolute;
}

.live-tile .tts-grey {
    color: grey !important;
}

.live-tile .left-icon .move-l {
    position: absolute;
    /*animation-name: fadein;*/
    /*animation-duration: 1000ms;*/
    /*animation-iteration-count: infinite;*/
}

.live-tile .bcu-disconnect {
    height: 76%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.live-tile .bcu-disconnect img{
    height: calc((var(--tile-height) + var(--tile-width-px)) / 7.8);
}

.live-tile .flex-updating {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.live-tile .updating {
    color: #fff;
    /*margin-top: calc((var(--tile-height) + var(--tile-width-px)) / 30);*/
    font-size: calc((var(--tile-height) + var(--tile-width-px)) / 30);
    padding-bottom: calc((var(--tile-height) + var(--tile-width-px)) / 35);
    text-align: center;
    height: calc((var(--tile-height) + var(--tile-width-px))/11);
    margin-top: calc(-1 * (var(--tile-height) + var(--tile-width-px))/23);
}

.tile .error {
    min-height: auto;
}
/*.live-tile .left-icon .move-l {*/
    /*position: absolute;*/
    /*animation-name: fadeout;*/
    /*animation-duration: 1000ms;*/
    /*animation-iteration-count: infinite;*/
/*}*/

/*@keyframes fadein {*/
    /*0% {*/
        /*visibility: hidden*/
    /*}*/
    /*!*25%  { visibility: hidden }*!*/
    /*!*50%  {background-color: blue;}*!*/
    /*100% {*/
        /*visibility: hidden;*/
    /*}*/
/*}*/

/*@keyframes fadeout {*/
    /*0% {*/
        /*visibility: hidden*/
    /*}*/
    /*!*25%  { visibility: hidden }*!*/
    /*!*50%  {background-color: blue;}*!*/
    /*100% {*/
        /*visibility: visible;*/
    /*}*/
/*}*/

/*@keyframes fadein {*/
    /*from {*/
        /*visibility: hidden;*/
    /*}*/
    /*to {*/
        /*visibility: visible;*/
    /*}*/
/*}*/



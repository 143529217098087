.dashboard-cc-admin {
    font-size: 20px;
}

.dashboard-cc-admin .isDisconnected {
    color: #000000;
    background-color: #F4AD9C;
}

.dashboard-cc-admin .data {
    padding: 5px 0;
    position: relative;
}

.dashboard-cc-admin .tabs {
    /*border-bottom: 5px solid var(--main-color);*/
    margin-right: 20px;
    margin-bottom: 20px;
}

.dashboard-cc-admin .devider {
    border-bottom: 7px solid var(--main-color);
    position: absolute;
    width: calc(100% - 0px);
    top: 34px;
    left: 0;
}

.dashboard-cc-admin .tabs a {
    text-align: center;
    /*min-width: 165px;*/
    padding: 0 18px;
    display: inline-block;
    /*border-bottom: 5px solid black;*/
    text-decoration: none !important;
    border: 1px solid rgba(200, 200, 200, 1.0);
    border-bottom: 0;
    border-right: 0;
    border-radius: 8px 8px 0 0;
    color: black !important;
    cursor: pointer;
}

.dashboard-cc-admin .tabs a:last-child {
    border-right: 1px solid rgba(200, 200, 200, 1.0);
}

.dashboard-cc-admin .tabs a:hover {
    text-decoration: none;
}

.dashboard-cc-admin .tabs a.selected {
    font-weight: bold;
    /*border: 1px solid var(--main-color);*/
    /*border-bottom: 5px solid rgba(1, 1, 1, 0);*/
    /*border-radius: 8px 8px 0 0;*/
    text-decoration: underline;
    background-color: var(--main-color);
    color: white !important;
}

.dashboard-cc-admin .inline {
    vertical-align: top;
    display: inline-block;
    margin-right: 20px;
}

.dashboard-cc-admin .table-descr {
    font-weight: bold;
    margin-bottom: 5px;
}

.dashboard-cc-admin table {
    font-size: 20px;
}

.dashboard-cc-admin .esp-table.spare-mat {
    width: 250px;
    max-height: 80vh;
}

.dashboard-cc-admin .esp-table.spare-mat .esp-table-header {
    line-height: 3.5;
}

.dashboard-cc-admin .esp-table.spare-mat td.max {
    width: 249px !important;
}

.dashboard-cc-admin .esp-table.spare-mat td {
    width: 250px;
}

.dashboard-cc-admin .esp-table.nurse-admin-account {
    width: 510px;
    /*max-height: 370px;*/
}

.dashboard-cc-admin .esp-table.nurse-admin-account td.max {
    width: 509px !important;
}

.dashboard-cc-admin .esp-table.nurse-admin-account td {
    width: 155px;
}

.dashboard-cc-admin .esp-table.nurse-admin-account td:last-child {
    width: 200px;
}

.dashboard-cc-admin .esp-table.nurse-admin-account .esp-table-header {
    line-height: 3.5;
}

.dashboard-cc-admin .esp-table.pathname {
    width: 830px;
    max-height: 90px;
}

.dashboard-cc-admin .esp-table.pathname td.max {
    width: 829px !important;
}

.dashboard-cc-admin .esp-table.pathname td {
    width: 200px;
    height: 45px;
    text-align: left;
    padding-left: 10px !important;
    font-weight: bold;
    background-color: #E5E5E5;
}

.dashboard-cc-admin .esp-table.pathname td:nth-child(2) {
    width: 630px;
    font-weight: normal;
    background-color: white;
}

.dashboard-cc-admin .mt40 {
    margin-top: 40px;
}

.dashboard-cc-admin .esp-table.operation-mats {
    width: 1565px;
    /*height: 360px;*/
}

.dashboard-cc-admin .esp-table.operation-mats .esp-table-body{
    max-height: 650px;
}

.dashboard-cc-admin .esp-table.operation-mats td.max {
    width: 1564px !important;
}

.dashboard-cc-admin .esp-table.operation-mats .esp-table-header {
    line-height: 3.5;
}

.dashboard-cc-admin .esp-table.operation-mats td {
    width: 150px;
}

.dashboard-cc-admin .esp-table.operation-mats td:nth-child(4),
.dashboard-cc-admin .esp-table.operation-mats td:nth-child(6) {
    width: 240px;
}

.dashboard-cc-admin .esp-table.operation-mats td:nth-child(5) {
    width: 190px;
}

.dashboard-cc-admin .esp-table.operation-mats td:nth-child(7),
.dashboard-cc-admin .esp-table.operation-mats td:nth-child(8) {
    width: 92px;
}

.dashboard-cc-admin .esp-table.operation-mats td:first-child {
    width: 110px;
}

.dashboard-cc-admin .esp-table.operation-mats td:last-child {
    width: 300px;
    text-align: left;
    padding-left: 10px !important;
}

.dashboard-cc-admin .esp-table.mat-replacement {
    width: 1565px;
}

.dashboard-cc-admin .esp-table.mat-replacement td.max {
    width: 1564px !important;
}

.dashboard-cc-admin .esp-table.mat-replacement .esp-table-header {
    line-height: 3.5;
}

.dashboard-cc-admin .esp-table.mat-replacement .esp-table-body {
    /*height: 650px;*/
    /*height: 350px;*/
}

.dashboard-cc-admin .esp-table.mat-replacement td {
    width: 110px;
}

.dashboard-cc-admin .esp-table.mat-replacement td:nth-child(5) {
    width: 150px;
}

.dashboard-cc-admin .esp-table.mat-replacement td:nth-child(6),
.dashboard-cc-admin .esp-table.mat-replacement td:nth-child(7),
.dashboard-cc-admin .esp-table.mat-replacement td:nth-child(8) {
    width: 200px;
}

.dashboard-cc-admin .esp-table.mat-replacement td:first-child {
    width: 140px;
}

.dashboard-cc-admin .esp-table.mat-replacement td:last-child {
    width: 345px;
    text-align: left;
    padding-left: 10px !important;
}

.dashboard-cc-admin .esp-table.thresholds {
    width: 1310px;
}

.dashboard-cc-admin .esp-table.thresholds td.max {
    width: 1309px !important;
    font-style: italic;
}

.dashboard-cc-admin .esp-table.thresholds .esp-table-header td {
    line-height: 1.15;
}

.dashboard-cc-admin .esp-table.thresholds .esp-table-body {
    /*height: 550px;*/
}

.dashboard-cc-admin .esp-table.thresholds td {
    word-break: unset;
}

.dashboard-cc-admin .esp-table.thresholds td.w1 {
    width: 98px;
}

.dashboard-cc-admin .esp-table.thresholds td.w2 {
    width: 98px;
}

.dashboard-cc-admin .esp-table.thresholds td.w3 {
    width: 165px;
}

.dashboard-cc-admin .esp-table.thresholds td.w4 {
    width: 256px;
}

.dashboard-cc-admin .esp-table.thresholds td.w5 {
    width: 155px;
}

.dashboard-cc-admin .esp-table.thresholds td.w6 {
    width: 108px;
}

.dashboard-cc-admin .esp-table.thresholdsN2 {
    width: 1312px;
}

.dashboard-cc-admin .esp-table.thresholdsN2 td.max {
    width: 1311px !important;
    font-style: italic;
}

.dashboard-cc-admin .esp-table.thresholdsN2 .esp-table-header td {
    line-height: 1.15;
}

.dashboard-cc-admin .esp-table.thresholdsN2 .esp-table-body {
    /*height: 550px;*/
}

.dashboard-cc-admin .esp-table.thresholdsN2 td {
    word-break: unset;
}

.dashboard-cc-admin .esp-table.thresholdsN2 td.w1 {
    width: 98px;
}

.dashboard-cc-admin .esp-table.thresholdsN2 td.w2 {
    width: 98px;
}

.dashboard-cc-admin .esp-table.thresholdsN2 td.duration {
    width: 198px;
}

.dashboard-cc-admin .esp-table.thresholdsN2 td.w3 {
    width: 165px;
}

.dashboard-cc-admin .esp-table.thresholdsN2 td.w4 {
    width: 256px;
}

.dashboard-cc-admin .esp-table.thresholdsN2 td.w5 {
    width: 155px;
}

.dashboard-cc-admin .esp-table.thresholdsN2 td.w6 {
    width: 108px;
}

.dashboard-cc-admin .esp-table.mode {
    width: 800px;
}

.dashboard-cc-admin .esp-table.mode td.max {
    width: 799px !important;
    font-style: italic;
}

.dashboard-cc-admin .esp-table.mode .esp-table-header td {
    line-height: 1.5;
}

.dashboard-cc-admin .esp-table.mode .esp-table-body {
    /*height: 650px;*/
}

.dashboard-cc-admin .esp-table.mode td {
    word-break: unset;
}

.dashboard-cc-admin .esp-table.mode td.w1 {
    width: 108px;
}

.dashboard-cc-admin .esp-table.mode td.w2 {
    width: 240px;
}

.dashboard-cc-admin .esp-table.mode td.w3 {
    width: 180px;
}

.dashboard-cc-admin .esp-table.mode td.w4 {
    width: 164px;
}

.dashboard-cc-admin .esp-table.mode td.w5 {
    width: 272px;
}

.dashboard-cc-admin .esp-table.thresholds1P {
    width: 722px;
}

.dashboard-cc-admin .esp-table.thresholds1P td.max {
    width: 721px !important;
    font-style: italic;
}

.dashboard-cc-admin .esp-table.thresholds1P .esp-table-header td {
    line-height: 1.15;
}

.dashboard-cc-admin .esp-table.thresholds1P .esp-table-body {
    /*height: 550px;*/
}

.dashboard-cc-admin .esp-table.thresholds1P td {
    word-break: unset;
}

.dashboard-cc-admin .esp-table.thresholds1P td.w1 {
    width: 98px;
}

.dashboard-cc-admin .esp-table.thresholds1P td.w2 {
    width: 98px;
}

.dashboard-cc-admin .esp-table.thresholds1P td.w3 {
    width: 165px;
}

.dashboard-cc-admin .esp-table.thresholds1P td.w4 {
    width: 256px;
}

.dashboard-cc-admin .esp-table.thresholds1P td.w5 {
    width: 155px;
}

.dashboard-cc-admin .esp-table.thresholds1P td.w6 {
    width: 108px;
}

/* Mat ledger */
.dashboard-cc-admin .esp-table.list {
    width: 1570px;
}

.dashboard-cc-admin .esp-table.list td {
    width: 190px;
    padding: 0 15px !important;
    height: 35px;
}

.dashboard-cc-admin .esp-table.list .esp-table-header {
    line-height: 3.0;
}

.dashboard-cc-admin .esp-table.list td.max {
    width: 1569px !important;
}

.dashboard-cc-admin .esp-table.thresholds1P td.w6 {
    width: 108px;
}
.dashboard-cc-admin .esp-table.list td:nth-child(6),
.dashboard-cc-admin .esp-table.list td:nth-child(8) {
    width: 165px;
}

.dashboard-cc-admin .esp-table.list td:nth-child(9) {
    width: 145px;
}

.dashboard-cc-admin .esp-table.list td:nth-child(1) {
    width: 215px;
}

.dashboard-cc-admin .esp-table.list td:nth-child(2){
    width: 100px;
}

.dashboard-cc-admin .esp-table.list td:nth-child(3){
    width: 160px;
}

.dashboard-cc-admin .esp-table.list td:nth-child(4) {
    width: 160px;
}


.dashboard-cc-admin .esp-table.list td:nth-child(7) {
    width: 270px;
}

.dashboard-cc-admin .esp-table.list .esp-table-body {
    /*height: 60vh;*/
}

.dashboard-cc-admin .table-descr {
    position: relative;
    width: 1570px;
}

.dashboard-cc-admin .export-ledger {
    font-weight: normal;
    text-decoration: underline;
    position: absolute;
    right: 0;
    cursor: pointer;
    color: #006ce5;
}

.dashboard-cc-admin .ml90 {
    margin-left: 90px;
}
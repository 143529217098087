.number-select {
    position: relative;
}

.number-select-block {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 1px solid rgb(170, 170, 170);
    position: absolute;
    top: -57px;
    left: 8px;
    height: 153px;
    width: 43px;
    z-index: 2;
    background-color: rgb(247, 246, 246);
    overflow-y: visible !important;
    overflow-x: hidden;
    color: #444444;
    user-select: none;
    -webkit-overflow-scrolling: touch;
}

.number-select-block ul {
    text-align: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.number-select-block ul li {
    text-decoration: none;
    list-style-type: none;
}

.number-select-block ul li.corner-color {
    opacity: 0.3;
    font-size: 18px !important;
}

.number-select-block ul li.focus-color {
    background-color: #AAAAAA;
    color: #fff;
}


.number-select-block::-webkit-scrollbar {
    display: none;
}

.number-select-block {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.disable-scroll{
    overflow-y: hidden;
}
.cc-admin-change-pw {
    font-size: 20px;
}

.cc-admin-change-pw a {
    text-decoration: underline !important;
    cursor: pointer;
    color: #2f76b5;
    font-weight: normal;
}

.cc-admin-change-pw .change-pw-modal .modal-content {
    width: 486px;
    min-height: 434px;
    box-shadow: 5px 5px 10px #888888;
}

.cc-admin-change-pw .change-pw-modal .modal-content .modal-body {
    padding: 10px 50px;
}

.cc-admin-change-pw .change-pw-modal .modal-content .modal-header {
    height: 53px;
}

.cc-admin-change-pw .change-pw-modal .modal-content .modal-footer {
    height: 58px;
    min-height: 58px;
    line-height: 1.0;
    padding: 5px 0;
}

.cc-admin-change-pw .change-pw-modal .modal-content button{
    background-color: white;
    border: none;
    color: #007bff;
    text-decoration: underline;
}

.cc-admin-change-pw .change-pw-modal .modal-content .mt20 {
    margin-top: 20px;
}

.cc-admin-change-pw .change-pw-modal .modal-content .mt50 {
    margin-top: 50px;
}

.cc-admin-change-pw .change-pw-modal .modal-content input {
    width: 100%;
    font-size: 20px;
    padding-left: 15px;
    height: 34px;
}

.cc-admin-change-pw .change-pw-modal .modal-content input::placeholder {
    font-size: 20px;
    font-style: italic;
}


/*.cc-admin-change-pw .change-pw-modal .modal-content {*/
    /*width: 940px;*/
/*}*/

/*.cc-admin-change-pw .esp-table.replace-mats {*/
    /*width: 840px;*/
/*}*/

/*.cc-admin-change-pw .esp-table.replace-mats .esp-table-header {*/
    /*background-color: var(--main-color);*/
    /*color: white;*/
/*}*/

/*.cc-admin-change-pw .esp-table.replace-mats td {*/
    /*width: 250px;*/
    /*text-align: center;*/
    /*padding-left: 10px !important;*/
    /*height: 39px;*/
    /*border: 1px solid #a0a0a0;*/
/*}*/

/*.cc-admin-change-pw .esp-table.replace-mats td:last-child {*/
    /*width: 90px;*/
/*}*/
.rc-time-picker-panel-narrow{
    max-width: 150px;
    width: auto;
}

.rc-time-picker-panel-inner {
    font-size: 16px;
    font-family: var(--main-font), sans-serif;
    color: black;
}

.rc-time-picker-panel-select {
    width: 74px;
}

.rc-time-picker-input {
    font-size: 16px;
    color: black;
}

.rc-time-picker-panel-input {
    padding: 0 5px;
}

.rc-time-picker-panel-select li {
    font-size: 16px;
}
/* one report */
.one-rept {
    margin-bottom: 10px;
    font-size: 12px;
}

.rept-content .one-rept-header {
    display: flex;
    align-items: center;
    height: 20px;
}

.rept-content .one-rept-header > div {
    width: 90px;
}

.rept-content .one-rept-header > div.header-date {
    font-weight: bold;
}

.rept-content .one-rept-header .min-resp-val,
.rept-content .one-rept-header .max-resp-val,
.rept-content .one-rept-header .low-resp-val,
.rept-content .one-rept-header .high-resp-val,
.rept-content .one-rept-header .min-pr-val,
.rept-content .one-rept-header .max-pr-val,
.rept-content .one-rept-header .low-pr-val,
.rept-content .one-rept-header .high-pr-val {
    font-weight: bold;
}

.rept-content .one-rept-header > div.low-resp-evt,
.rept-content .one-rept-header > div.high-resp-evt,
.rept-content .one-rept-header > div.low-pr-evt,
.rept-content .one-rept-header > div.high-pr-evt {
    width: 130px;
    text-align: center;
}

.rept-content .one-rept-header > div.low-resp-evt {
    border: 1px solid rgb(33, 150, 243);
    background-color: rgba(145, 228, 251, 0.5);
    /*padding: 2px;*/
    /*margin: 1px 5px;*/
}

.rept-content .one-rept-header > div.high-resp-evt {
    border: 1px solid rgb(230, 22, 16);
    background-color: rgba(255, 138, 132, 0.5);
    padding: 2px;
    margin: 1px 150px 1px 5px;
}

.rept-content .one-rept-header > div.low-pr-evt {
    border: 1px solid rgb(52, 226, 92);
    background-color: rgba(52, 226, 92, 0.5);
    padding: 2px;
    margin: 1px 5px;
}

.rept-content .one-rept-header > div.high-pr-evt {
    border: 1px solid rgb(218, 166, 8);
    background-color: rgba(218, 166, 8, 0.5);
    padding: 2px;
    margin: 1px 5px;
}

/* one report charts */
.rept-content .one-rept-content-wrapper {
    display: flex;
    border: 1px solid #9c9c9c;
    margin: 3px;
    height: 60px;
}

.one-rept-content .one-rept-content-wrapper > div.info {
    min-width: 100px;
    display: flex;
    flex-direction: row;
    flex: 3;
}

.one-rept-content .one-rept-content-wrapper > div.info > div {
    width: 33%;
    padding: 0 7px;
}

.one-rept-content .one-rept-content-wrapper > div.info > div.canvas-name {
    font-weight: bold;
    font-size: 12px;
}

.one-rept-content .one-rept-content-wrapper > div.info > div.canvas-measurement {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    text-align: right;
}

.one-rept-content .one-rept-content-wrapper > div.info > div.canvas-ruler {
    text-align: right;
    /*width: 15%;*/
    font-size: 8px;
}

.one-rept-content .one-rept-content-wrapper > div.info > div.canvas-measurement div:nth-child(1) {
    margin: 65% 0 0 0;
}

.one-rept-content .one-rept-content-wrapper > div.info > div.canvas-measurement div:nth-child(2) {
    margin: 5px 0 0 0;
    width: 25px;
}

/* .one-rept-content .one-rept-content-wrapper > canvas { */
/* width: 1800px;
height: 50px; */
/* } */

.one-rept-content .one-rept-content-wrapper text {
    font-size: 8px;
}
.report-date-picker {
    text-transform: none;
    font-weight: normal;
    font-size: 20px;
    width: 320px;
    color: #fff;
}

.report-date-picker .name-12 .caret-left,
.report-date-picker .name-12 .caret-right {
    font-size: 40px;
    vertical-align: top;
    cursor: pointer;
    opacity: .4;
    pointer-events: none;
    margin-top: -5px;
}

.report-date-picker .name-12 .caret-left.active,
.report-date-picker .name-12 .caret-right.active {
    color: #fff;
    pointer-events: auto;
    opacity: 1.0;
}

.report-date-picker .date-label {
    display: inline-block;
    margin-right: 15px;
}

.report-date-picker .name-12 .date {
    font-weight: bold;
    padding: 0 20px;
    vertical-align: top;
    display: inline-block;
    margin-top: 0;
    cursor: pointer;
    user-select: none;
}

.rept-content {
    height: 934px;
}

/*.rept-content legend {*/
    /*display: block;*/
    /*width: auto;*/
    /*max-width: 100%;*/
    /*margin-bottom: 0;*/
    /*line-height: 1.0;*/
    /*color: inherit;*/
    /*white-space: normal;*/
    /*font-size: 20px;*/
    /*padding: 0 5px;*/
/*}*/

.fieldset {
    border: 2px groove threedface;
    border-top: none;
    padding: 1.2em 0.1em 0.1em 0.1em;
    margin: 15px 2px 2px 2px;
    position: relative;
}

.fieldset > .legend {
    /*margin: -1.0em -0.1em 0 10px;*/
    background-color: #fff;
    position: absolute;
    top: -15px;
    margin: 0 10px;
    padding: 0 5px;
}
/*.fieldset > .legend:before {*/
    /*border-top: 2px groove threedface;*/
    /*content: ' ';*/
    /*float: left;*/
    /*margin: -1.5em 2px 0 -1px;*/
    /*width: 0.75em;*/
/*}*/
/*.fieldset > .legend:after {*/
    /*border-top: 2px groove threedface;*/
    /*content: ' ';*/
    /*display: block;*/
    /*height: 1.5em;*/
    /*left: 2px;*/
    /*margin: 0 1px 0 0;*/
    /*overflow: hidden;*/
    /*position: relative;*/
    /*top: 0.5em;*/
/*}*/

.vital-sign-header {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.vital-sign-header .statistics {
    width: 995px;
}

.vital-sign-header .cursor-measurement {
    width: 877px;
    margin-left: 20px;
}

.vital-sign-header .statistics .legend b {
    padding-right: 5px;
}

.vital-sign-header .statistics .legend img {
    width: 24px;
}

.vital-sign-header .statistics > .fieldset {
    display: flex;
    align-items: center;
}

.vital-sign-header .statistics .fieldset.resp-statistics,
.vital-sign-header .statistics .fieldset.pr-statistics,
.vital-sign-header .statistics .fieldset.event-statistics {
    width: 32%;
    display: flex;
    flex-direction: column;
    margin: 10px 4px 7px 8px;
    padding: 20px 10px 10px 15px;
    height: 190px;
}

.vital-sign-header span.w150px {
    width: 190px !important;
    display: inline-block;
}

.vital-sign-header .statistics .fieldset.event-statistics div {
    margin: 0 0 5px 0;
}

.vital-sign-header .cursor-measurement > .fieldset {
    display: flex;
    /*padding: 25px 15px 0 15px;*/
}

.vital-sign-header .cursor-measurement .fieldset.fieldset-readings,
.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold {
    display: flex;
    flex-direction: column;
    margin: 10px 10px 7px 10px;
    padding: 10px;
    height: 190px;
    align-items: start;
    justify-content: center;
}

.vital-sign-header .cursor-measurement .fieldset.fieldset-readings {
    width: 230px;
    border-color: rgb(20, 100, 246);
    margin-left: 25px;
}

.vital-sign-header .cursor-measurement .fieldset.fieldset-readings .legend {
    color: rgb(20, 100, 246);
}

.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold {
    width: 540px;
    font-size: 35px;
    margin-left: 38px;
}

.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .legend{
    font-size: 20px;
}

.vital-sign-header .cursor-measurement .fieldset.fieldset-readings img,
.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold  img {
    width: 36px;
}

.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .th-resp,
.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .th-pr {
    height: 90px;
    display: flex;
    align-items: center;
}


.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .th-pr-label,
.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .th-resp-label {
    width: 110px;
    display: inline-block;
    text-align: center;
}


.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .low-wrapper,
.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .hi-wrapper {
    width: 205px;
    display: inline-block;
    text-align: center;
}

.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .th-resp-value-lo,
.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .th-resp-value-hi,
.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .th-pr-value-lo,
.vital-sign-header .cursor-measurement .fieldset.fieldset-threshold .th-pr-value-hi {
    width: 100px;
}

.vital-sign-header .cursor-measurement .cursor-date {
    margin-left: 30px;
    font-size: 20px;
    color: rgb(20, 100, 246);
}

/*.vital-sign-header .block-title {*/
    /*font-size: 35px;*/
    /*font-weight: bold;*/
/*}*/

/*.vital-sign-header .cursor-measurement .cursor-content {*/
    /*display: flex;*/
/*}*/

/*.vital-sign-header .cursor-measurement .cursor-content .block-1{*/
    /*display: flex;*/
    /*width: 325px;*/
/*}*/

/*.vital-sign-header .cursor-measurement .cursor-content .block-2{*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*margin-left: 20px;*/
/*}*/

/*.vital-sign-header .cursor-measurement .cursor-content .block-2 .th-row1{*/
    /*display: flex;*/
    /*width: 625px;*/
/*}*/

/*.vital-sign-header .cursor-measurement .cursor-content .block-2 .th-row1 fieldset{*/
    /*min-width: 302px;*/
    /*max-width: 302px;*/
/*}*/

/*.vital-sign-header .cursor-measurement .cursor-content .block-2 .th-row2{*/
    /*display: flex;*/
    /*width: 625px;*/
/*}*/

.vital-sign-header .fieldset {
    border: 1px solid rgb(170, 170, 170);
    border-radius: 6px;
}

.vital-sign-header .fieldset.fieldset-statistics {
    display: flex;
    height: 240px;
    padding: 10px 20px 10px 20px;
    margin: 0;
}

.vital-sign-header .fieldset.fieldset-statistics > div > div {
    /* border: 1px solid; */
    height: 43px;
    vertical-align: top;
    display: flex;
    justify-content: left;
    align-items: center;
}

.vital-sign-header .fieldset.fieldset-statistics > div > div img{
    margin-right: 13px;
    width: 24px;
    /*height: 35px;*/
}

.vital-sign-header .fieldset span {
    padding: 0 5px 0 0;
}

/*.vital-sign-header .min-block {*/
    /*width: 190px;*/
/*}*/

/*.vital-sign-header .max-block {*/
    /*width: 330px;*/
/*}*/

/*.vital-sign-header .w150px {*/
    /*width: 150px !important;*/
    /*min-width: 150px !important;*/
/*}*/

/*.vital-sign-header .w185px {*/
    /*width: 185px !important;*/
    /*min-width: 185px !important;*/
/*}*/

/*.vital-sign-header .w110px {*/
    /*width: 110px !important;*/
    /*min-width: 110px !important;*/
/*}*/

/*.vital-sign-header .w100px {*/
    /*width: 115px !important;*/
/*}*/

/*.vital-sign-header .w48px {*/
    /*width: 45px !important;*/
    /*height: 32px !important;*/
/*}*/

/*.vital-sign-header .w85px {*/
    /*width: 85px !important;*/
/*}*/

/*.vital-sign-header .w74px {*/
    /*width: 85px !important;*/
/*}*/

/*.vital-sign-header .w320px {*/
    /*width: 332px !important;*/
/*}*/

/*.vital-sign-header .w250px {*/
    /*width: 250px !important;*/
/*}*/

/*.vital-sign-header .w328px {*/
    /*width: 328px !important;*/
/*}*/

/*.vital-sign-header .alert {*/
    /*position: relative;*/
    /*padding: 0;*/
    /*margin-bottom: 0;*/
    /*border: 0;*/
    /*border-radius: 0;*/
/*}*/

/*.vital-sign-header .mr30 {*/
    /*margin-right: 30px;*/
/*}*/

.vital-sign-header .text-bordered {
    display: inline-block;
    padding: 2px 5px;
    border: 1px solid rgb(170, 170, 170);
    border-radius: 5px;
    width: 80px;
    text-align: center;
    font-weight: bold;
}

.vital-sign-header .text-orange {
    background-color: rgb(255, 178, 83);
    border-color: rgb(214, 130, 39);
    display: inline-block;
    margin-top: 15px;
    vertical-align: top;
}

.vital-sign-header .text-blue {
    background-color: rgba(145, 228, 251, 0.5);
    border-color: rgb(33, 150, 243);
}

.vital-sign-header .fieldset-readings {
    width: 325px;
    height: 240px;
    padding: 10px 10px 10px 30px;
    margin: 0;
}

.vital-sign-header .reading-resp {
    font-size: 35px;
    display: flex;
    align-items: center;
    height: 90px;
    text-align: center;
    justify-content: center;
}


.vital-sign-header span.reading-text {
    width: 80px;
}

.vital-sign-header span.w100px {
    width: 100px;
}

/*.vital-sign-header fieldset.fieldset-thresholds,*/
/*.vital-sign-header fieldset.fieldset-others,*/
/*.vital-sign-header fieldset.fieldset-op {*/
    /*height: 112px;*/
    /*padding: 4px 20px 10px 20px;*/
    /*margin: 0;*/
/*}*/

/*.vital-sign-header fieldset.fieldset-thresholds {*/
    /*margin: 0 20px 16px 0;*/
/*}*/

/*.vital-sign-header fieldset.fieldset-op {*/
    /*width: 625px;*/
/*}*/

/*.vital-sign-header fieldset.fieldset-thresholds > div ,*/
/*.vital-sign-header fieldset.fieldset-others > div {*/
    /*height: 40px;*/
    /*display: flex;*/
    /*justify-content: center;*/
/*}*/

/*.vital-sign-header fieldset.fieldset-op > div {*/
    /*height: 40px;*/
    /*display: flex;*/
    /*justify-content: left;*/
/*}*/


/*.vital-sign-header .right-wrapper {*/
    /*text-align: right;*/
/*}*/

.vital-sign-header .highlight {
    background-color: rgb(254, 250, 192);
}

.vital-sign-header .highlight-red {
    color: rgb(230, 22, 16);
}

.vital-sign-content {
    margin-top: 0;
}

.vital-sign-content .rept-day {
    font-size: 20px;
    font-weight: bold;
}

.vital-sign-content .content-wrapper {
    height: 320px;
    border: 2px solid rgb(68, 68, 68);
    margin: 0 10px 10px 0;
    display: flex;
    font-size: 20px;
}

.vital-sign-content .content-wrapper > div.info {
    display: flex;
    width: 117px;
}

.vital-sign-content .content-wrapper > div.info .canvas-name {
    margin: 0 0 0 15px;
    font-weight: bold;
}

.vital-sign-content .content-wrapper > div.info .canvas-measurement {
    display: flex;
    align-items: end;
    width: 30%;
    position: relative;
}

.vital-sign-content .content-wrapper > div.info .time-text {
    margin-bottom: 3px;
    margin-left: 10px;
    position: absolute;
    font-weight: bold;
    width: 100px;
}

.vital-sign-content .content-wrapper > div.info .canvas-ruler {
    height: 295px;
    width: 30%;
    text-align: right;
}

.vital-sign-content .content-wrapper > div.info .canvas-ruler-unit {
    height: 22%;
    display: flex;
    justify-content: center;
    align-items: start;
}

.vital-sign-content .content-wrapper > div.info .canvas-ruler-unit:last-child{
    height: 12%;
}

.vital-sign-content .content-wrapper img {
    width: 35px;
    margin-top: 10px;
}

.vital-sign-header .fieldset-op .text-bordered,
.vital-sign-header .fieldset-others .text-bordered {
    height: 35px
}

.vital-sign-content .svg-text-th {
    font-style: italic;
    font-size: 16px;
}
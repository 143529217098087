.reset-password {

}

.reset-password .iresp-modal .modal-content {
    min-height: 200px;
    width: 400px;
}

.reset-password .iresp-modal .modal-body {
    min-height: 100px;
}

.reset-password .mt15 {
    margin-top: 15px;
}
/* report header */
.rept-header {
    display: flex;
    border-bottom: 1px solid black;
    width: 1904px;
}

.rept-header > div {
    width: 31%;
}

.rept-header .rept-header-left {
    text-align: left;
    font-size: 20px;
}

.rept-header .rept-header-center {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 135px;
    justify-content: center;
    align-items: flex-start;
    width: 35%;
}

.rept-header .rept-header-right {
    text-align: right;
    font-size: 20px;
}

.rept-header .mt10 {
    margin-top: 10px;
}

.rept-header .rept-header-left .center-name {
    font-weight: bold;
    margin-top: -5px;
}

.rept-header .rept-header-left .patient-name-val {
    font-weight: bold;
}

.rept-header .rept-header-right .bed-name-val {
    font-weight: bold;
}

.rept-header .rept-header-right .date-report-val {
    font-weight: bold;
}

.rept-header .report-title {
    font-weight: bold;
    font-size: 30px;
}

.patient-info span {
    display: inline-block;
    width: 220px;
}

.patient-info b {
    display: inline-block;
    width: 320px;
}

/* report content */
.rept-content {
    margin-top: 10px;
    width: 1905px;
    height: 915px;
}
html {
    overflow: hidden;
}

body {
    overflow: hidden;
    min-height: 100vh;
    user-select: none;
}

input[type=checkbox] {
    border: 2px solid #2196F3 !important;
    border-radius: 2px;
    -moz-appearance: none;
    -webkit-appearance: none;
}

input[type=checkbox].disabled {
    border:  2px solid grey !important;
}

input[type=checkbox]:checked {
    -moz-appearance: checkbox;
    -webkit-appearance: checkbox;
}

input::placeholder {
    font-style: italic !important;
    font-family: var(--main-font), sans-serif;
    font-size: inherit;
    color: #AAAAAA;
}

td.max {
    font-style: italic;
    text-align: center !important;
}

a.disabled {
    color: grey !important;
    pointer-events: none;
}

.error {
    color: #e61610;
    min-height: 44px;
    max-width: 100% !important;
}

.error div {
    /*border: 1px dashed;*/
    min-width: 350px;
    padding: 0 5px;
}

.success {
    height: auto;
    font-style: italic;
    font-size: 20px;
}

.flashing {
    color: red;
    animation-name: flashing;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}

.border-red {
    border: 1px solid red !important;
}

.abnormalEl {
    color: #000000;
    background-color: #F4AD9C;
}

.abnormalElS2 {
    color: #DB3B26;
}

.color-red {
    /*color: #DB3B26 !important;*/
    color: red !important;
}

.note-red {
    color: #dc4c18 !important;
    font-weight: bold;
}

.color-grey {
    color: grey;
}

.lh15 {
    line-height: 1.5;
}

.underline {
    text-decoration: underline;
}

.descr {
    opacity: 0.4;
    font-style: italic;
    margin-bottom: 10px;
}

@keyframes flashing {
    from {opacity: 1.0}
    to {opacity: 0.4}
}

.pointer {
    cursor: pointer;
}

.container-fluid {
    /*width: 1915px !important;*/
}

.inline {
    display: inline-block;
}

.zoom {
    /*zoom: var(--main-zoom);*/
}

.zoom-2 {
    /*zoom: 1.0;*/
}

.zoom-3 {
    /*zoom: 1.0;*/
}

:root {
    --main-color: #43CABD;
    --main-body-width: calc(100% - 60px);
    --main-body-margin: 25px;
    --main-zoom: 1.0;
    --tile-height: calc(100vw * 83 / 100 / 8);
    --tile-width: calc(100vw / 8);
    --tile-width-px: calc(100vw / 8);
    --edit-index: 1;
    --overflow: hidden;
    --main-font: "Noto";
}
.iresp-dropdown {
    display: inline-block;
    margin-left: 20px;
}

.iresp-dropdown .select {
    width: 235px;
    height: 31px;
    border: 1px solid #909090;
    border-radius: 0.25em;
    /*padding: 0.25em 0.5em;*/
    cursor: pointer;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    display: grid;
    position: relative;
    align-items: center;
    grid-area: select;
    grid-template-areas: "select";
}

.iresp-dropdown .select-icon {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: #000;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    margin: 10px 10px 0 0;
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 0;
}

.iresp-dropdown select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 0 0 10px;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: 20px;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    font-style: italic;
    color: #909090;
    z-index: 1;
}

.iresp-dropdown select.selected {
    font-weight: bold;
    font-style: normal;
    color: #000;
}

.iresp-dropdown select option {
    font-style: normal;
    color: black;
    height: 35px;
}

.iresp-dropdown select option:disabled {
    font-style: italic;
    color: grey;
}
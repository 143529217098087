.na-manage {
    font-size: 20px;
}

.na-manage table td {
    position: relative;
}

.na-manage table td input[type=checkbox] {
    width: 20px;
    height: 20px;
    top: 0;
    position: absolute;
    margin-top: 10px;
    z-index: 2;
}

.na-manage table td input[type=checkbox].all {
    right: 5px;
}

.na-manage .data {
    padding: 5px 0;
    position: relative;
}

.na-manage .tabs {
    /*border-bottom: 5px solid var(--main-color);*/
    margin-right: 20px;
    /*margin-bottom: 10px;*/
}

.na-manage .devider {
    border-bottom: 7px solid var(--main-color);
    position: absolute;
    width: calc(100% - 0px);
    top: 34px;
    left: 0;
}

.na-manage .tabs a {
    text-align: center;
    /*min-width: 165px;*/
    padding: 0 20px;
    display: inline-block;
    /*border-bottom: 5px solid black;*/
    text-decoration: none !important;
    border: 1px solid rgba(200, 200, 200, 1.0);
    border-bottom: 0;
    border-right: 0;
    border-radius: 8px 8px 0 0;
    color: black !important;
    cursor: pointer;
}

.na-manage .tabs a:last-child {
    border-right: 1px solid rgba(200, 200, 200, 1.0);
}

.na-manage .tabs a:hover {
    text-decoration: none;
}

.na-manage .tabs a.selected {
    font-weight: bold;
    /*border: 1px solid var(--main-color);*/
    /*border-bottom: 5px solid rgba(1, 1, 1, 0);*/
    /*border-radius: 8px 8px 0 0;*/
    text-decoration: underline;
    background-color: var(--main-color);
    color: white !important;
}

.na-manage .descr {
    opacity: 0.4;
    font-style: italic;
    margin-bottom: 10px;
}

.na-manage .table-descr {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.na-manage table {
    font-size: 20px;
}

.na-manage .esp-table.add-nurses {
    width: 591px;
}

.na-manage .esp-table.add-nurses input{
    border: none !important;
}

.na-manage .esp-table.add-nurses td {
    width: 295px;
    height: 40px;
    word-break: break-word;
}

.na-manage .esp-table.add-nurses td:nth-child(1) {
    text-align: left;
    padding-left: 10px !important;
}

.na-manage .esp-table.add-nurses .esp-table-body {
    max-height: 58vh;
}
.na-manage .esp-table.add-nurses a {
    text-decoration: underline;
    cursor: pointer;
}

.na-manage .esp-table.unassigned {
    width: 570px;
    position: relative;
}

.na-manage .esp-table.unassigned .esp-table-body {
    max-height: 325px !important;
}

.na-manage .esp-table.unassigned td.max {
    width: 569px !important;
}

.na-manage .inline.right {
    float: right;
    display: inline-block;
}

.na-manage .esp-table.unassigned td {
    height: 40px;
}

.na-manage .esp-table.unassigned td:nth-child(1) {
    width: 430px;
    text-align: left;
    padding-left: 10px !important;
}

.na-manage .esp-table.unassigned td:nth-child(2) {
    width: 139px;
}

.na-manage .esp-table.tx-unassigned {
    width: 570px;
    position: relative;
}

.na-manage .esp-table.tx-unassigned .esp-table-body {
    max-height: 650px;
}

.na-manage .esp-table.tx-unassigned td.max {
    width: 569px !important;
}

.na-manage .esp-table.tx-unassigned td {
    height: 40px;
}

.na-manage .esp-table.tx-unassigned td:nth-child(1) {
    width: 250px;
    text-align: left;
    padding-left: 10px !important;
}

.na-manage .esp-table.tx-unassigned td:nth-child(2) {
    width: 180px;
    text-align: left;
    padding-left: 10px !important;
}

.na-manage .esp-table.tx-unassigned td:nth-child(3) {
    width: 139px;
}

.na-manage .esp-table.available {
    width: 441px;
    position: relative;
}

.na-manage .esp-table.available .esp-table-body {
    max-height: 220px !important;
}

.na-manage .esp-table.available td.max {
    width: 439px !important;
}

.na-manage .esp-table.available td.wMax {
    width: 440px !important;
}

.na-manage .esp-table.available td {
    height: 40px;
    width: 220px;
    min-width: 220px;
}

.na-manage .action-holder {
    display: block;
    z-index: 1;
    position: absolute;
    margin: auto auto;
    top: 45%;
    color: #e61610;
    text-align: center;
    font-style: italic;
    width: 100%;
}

.na-manage .confirm-modal .modal-content {
    width: 420px;
}

.na-manage .confirm-modal .modal-content .modal-body {
    font-size: 16px;
}

.na-manage .confirm-modal .modal-content .modal-footer {
    font-size: 16px;
}

.na-manage .confirm-modal .modal-content .mt20 {
    margin-top: 20px;
}

.na-manage .link {
    color: #007bff;
    cursor: pointer;
}

.na-manage img {
    cursor: pointer;
}

.na-manage tr[_pose="exit"] {
    display: none;
}

.na-manage .disabled a {
    color: grey;
}

.na-manage .add-row {
    width: 591px;
}

.na-manage .add-row td {
    height: 43px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
}

.na-manage .add-row td:nth-child(1) {
    width: 296px;
    padding-left: 10px !important;
}

.na-manage .add-row td:nth-child(2) {
    width: 296px;
    text-align: center;
}

.na-manage .iresp-dropdown {
    margin-left: 10px;
}

.na-manage .iresp-dropdown .select {
    width: 240px;
}

.na-manage .iresp-dropdown .select-icon {
    right: 0;
}

.na-manage input[type=text] {
    border: none !important;
    width: 100%;
}

.na-manage .action-holder img {
    width: 200px;
}

.na-manage .esp-table-body {
    max-height: 610px !important;
}
.oac-password {
    position: relative;
}

.oac-password img {
    width: 30px !important;
    height: 20px !important;
    position: absolute;
    top: 16px;
    right: 7px;
}
.print-preview {
    position: fixed;
    top: 10%;
    left: 0;
    width: 90%;
    height: 90%;
    background: red;
    color: white;
}

.print-preview .report-content {
    /*zoom: var(--main-zoom);*/
}
.lv-btn {
    color: white;
    width: 115px;
    height: 31px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
}

.lv-btn.ja {
    width: 160px;
}

.lv-btn-red {
    background-color: #E61610;
}

.lv-btn-black {
    background-color: #444444;
}

.sidebar-item .alert-img {
    height: 44px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}